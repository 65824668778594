import iconSearch from "../../assets/icons/icon_search.svg";

const SearchInput = ({ containerClassname }) => {
  return (
    <form className={`mx-auto ${containerClassname}`}>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <img src={iconSearch} />
        </div>
        <input
          type="search"
          id="default-search"
          className="pl-[48px] text-[16px] font-[400] leading-[24px] tracking-[-0.32px]"
          placeholder="Search..."
          required
        />
      </div>
    </form>
  );
};

export default SearchInput;
