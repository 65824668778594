import iconEdit from "../../assets/icons/icon_edit.svg";
import iconTableEye from "../../assets/icons/icon_table_eye.svg";
import iconTableTrash from "../../assets/icons/icon_table_trash.svg";
import iconTableUpload from "../../assets/icons/icon_table_upload.svg";
import SortIconTable from "../../components/SortIconTable";
import Tooltip from "../../components/Tooltip";
import { MODE } from "../../constants";
import { stringToDateFormat } from "../../utils/time/dateFormat";

export const MANAGE_ACCOUNT_COLS = {
  GROUP_NAME: "group_name",
  COMPANY_NAME: "company_name",
  LASTEST_FISCAL_PERIOD: "latest_fiscal_period",
  UPDATED_AT: "updated_at",
  ACTIONS: "actions",
};

export const companyCols = {
  minWidth: 160 * 6,
  laptopMinWidth: 140 * 6,
  columns: ({
    handleManageClient,
    setselectedCompanyID,
    toggleDeleteModal,
    handleSort,
    isAscCols,
    handleUploadNewFinancials,
  }) => [
    {
      key: MANAGE_ACCOUNT_COLS.GROUP_NAME,
      renderHeader: () => (
        <th
          className="text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px] sticky left-[0] xl:w-[140px] 2xl:w-[230px]"
          onClick={() => handleSort(MANAGE_ACCOUNT_COLS.GROUP_NAME)}
        >
          <div className="flex items-center gap-[2px]">
            Group name
            <SortIconTable isAsc={isAscCols[MANAGE_ACCOUNT_COLS.GROUP_NAME]} />
          </div>
        </th>
      ),
      renderRow: ({ group_name }) => (
        <td className="p-[12px] sticky left-[0] !xl:w-[140px] !2xl:w-[230px] z-10">
          {group_name}
        </td>
      ),
    },
    {
      key: MANAGE_ACCOUNT_COLS.COMPANY_NAME,
      renderHeader: () => (
        <th
          className="text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50"
          onClick={() => handleSort(MANAGE_ACCOUNT_COLS.COMPANY_NAME)}
        >
          <div className="flex items-center gap-[2px]">
            Company name
            <SortIconTable
              isAsc={isAscCols[MANAGE_ACCOUNT_COLS.COMPANY_NAME]}
            />
          </div>
        </th>
      ),
      renderRow: ({ company_name }) => (
        <td className="p-[12px]">{company_name}</td>
      ),
    },
    {
      key: MANAGE_ACCOUNT_COLS.LASTEST_FISCAL_PERIOD,
      renderHeader: () => (
        <th
          className="text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50"
          onClick={() => handleSort(MANAGE_ACCOUNT_COLS.LASTEST_FISCAL_PERIOD)}
        >
          <div className="flex items-center gap-[2px]">
            Latest Fiscal Period
            <SortIconTable
              isAsc={isAscCols[MANAGE_ACCOUNT_COLS.LASTEST_FISCAL_PERIOD]}
            />
          </div>
        </th>
      ),
      renderRow: ({ latest_fiscal_period }) => (
        <td className="p-[12px]">{latest_fiscal_period}</td>
      ),
    },
    {
      key: MANAGE_ACCOUNT_COLS.UPDATED_AT,
      renderHeader: () => (
        <th
          className="text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50"
          onClick={() => handleSort(MANAGE_ACCOUNT_COLS.UPDATED_AT)}
        >
          <div className="flex items-center gap-[2px]">
            Date Modified
            <SortIconTable isAsc={isAscCols[MANAGE_ACCOUNT_COLS.UPDATED_AT]} />
          </div>
        </th>
      ),
      renderRow: ({ updated_at }) => (
        <td className="p-[12px]">{stringToDateFormat(updated_at)}</td>
      ),
    },
    {
      key: MANAGE_ACCOUNT_COLS.ACTIONS,
      renderHeader: () => (
        <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] sticky right-0">
          <div className="w-full text-center">Actions</div>
        </th>
      ),
      renderRow: ({ id }) => (
        <td className="flex justify-end p-[12px] sticky right-0 bg-[#fff]">
          <div className="flex items-center gap-[4px] justify-center max-w-[200px] w-full">
            <Tooltip
              tooltip="View Financial Spreads"
              tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]"
              handleClick={() => handleManageClient(id, MODE.VIEW)}
            >
              <img
                src={iconTableEye}
                className="p-[8px] cursor-pointer w-full"
              />
            </Tooltip>
            <Tooltip
              tooltip="Edit Financial Spreads"
              tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]"
              handleClick={() => handleManageClient(id, MODE.EDIT)}
            >
              <img src={iconEdit} className="p-[8px] cursor-pointer" />
            </Tooltip>

            <Tooltip
              tooltip="Upload New Financials"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]"
              handleClick={() => handleUploadNewFinancials(id)}
            >
              <img src={iconTableUpload} className="p-[8px] cursor-pointer" />
            </Tooltip>

            <Tooltip
              tooltip="Delete Account"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]"
              handleClick={() => {
                setselectedCompanyID(id);
                toggleDeleteModal();
              }}
            >
              <img src={iconTableTrash} className="p-[8px] cursor-pointer" />
            </Tooltip>
          </div>
        </td>
      ),
    },
  ],
};
