import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { CURRENCY_OPTIONS, DOCUMENT_TYPE_ENUM } from "../../constants";
import {
  API_URL_STANDARD_ACCOUNTS,
} from "../../constants/url";

const ApplicationContext = createContext({
  getStandardAccountOptions: (_documentType) => { },
  data: undefined,
  metadata: undefined,
  loadStandardAccountOptions: () => { },
  currencyOptions: [],
});

export const useApplicationContext = () => useContext(ApplicationContext);

// TODO: temporary implementation until spread management
export default function ApplicationProvider({ children }) {
  const [tpIsStandardAccountOptions, setTpIsStandardAccountOptions] = useState(
    []
  );
  const [tpCfStandardAccountOptions, setTpCfStandardAccountOptions] = useState(
    []
  );
  const [tpBsStandardAccountOptions, setTpBsStandardAccountOptions] = useState(
    []
  );
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});

  const fetchingIsTpStandardAccount = async () => {
    try {
      const res = await axios.get(
        `${API_URL_STANDARD_ACCOUNTS}/tp-is-standard-account-options`,
        {
          withCredentials: true,
        }
      );
      setTpIsStandardAccountOptions(
        res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
          return accountCodeA < accountCodeB
            ? -1
            : accountCodeA > accountCodeB
              ? 1
              : 0;
        })
      );
    } catch (error) {
      console.error(
        "Error fetching Income Statement TP Standard Account options:",
        error
      );
    }
  };

  const fetchingBsTpStandardAccount = async () => {
    try {
      const res = await axios.get(
        `${API_URL_STANDARD_ACCOUNTS}/tp-bs-standard-account-options`,
        {
          withCredentials: true,
        }
      );
      setTpBsStandardAccountOptions(
        res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
          return accountCodeA < accountCodeB
            ? -1
            : accountCodeA > accountCodeB
              ? 1
              : 0;
        })
      );
    } catch (error) {
      console.error(
        "Error fetching Balance Sheet TP Standard Account options:",
        error
      );
    }
  };

  const fetchingCfTpStandardAccount = async () => {
    try {
      const res = await axios.get(
        `${API_URL_STANDARD_ACCOUNTS}/tp-cf-standard-account-options`,
        {
          withCredentials: true,
        }
      );
      setTpCfStandardAccountOptions(
        res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
          return accountCodeA < accountCodeB
            ? -1
            : accountCodeA > accountCodeB
              ? 1
              : 0;
        })
      );
    } catch (error) {
      console.error(
        "Error fetching Cash Flow Statement TP Standard Account options:",
        error
      );
    }
  };

  const loadStandardAccountOptions = () => {
    fetchingIsTpStandardAccount();
    fetchingBsTpStandardAccount();
    fetchingCfTpStandardAccount();
  };

  useEffect(() => {
    // fetchingIsTpStandardAccount();
    // fetchingBsTpStandardAccount();
    // fetchingCfTpStandardAccount();
    loadStandardAccountOptions();
  }, []);

  const getStandardAccountOptions = (documentType) => {

    const tpStandardAccountOptionsMap = {
      [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: tpIsStandardAccountOptions,
      [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: tpBsStandardAccountOptions,
      [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: tpCfStandardAccountOptions,
    };
    return tpStandardAccountOptionsMap[documentType];
  };

  return (
    <ApplicationContext.Provider
      value={{
        getStandardAccountOptions,
        loadStandardAccountOptions,
        data,
        metadata,
        currencyOptions: CURRENCY_OPTIONS,
      }}
    >
      {children || <Outlet />}
    </ApplicationContext.Provider>
  );
}
