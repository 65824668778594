import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import Preview from "../../components/Preview";
import Upload from "../../components/Upload";
import { FILE_TYPE_PDF } from "../../constants/file";
import { API_APPLICATIONS, API_GET_COMPANIES, URL_APPLICATION_VALIDATE, URL_DASHBOARD } from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import { TOAST_TYPE } from "../../constants";
import axios from "axios";
import FinancialSpreadingForm from "../../components/FinancialSpreadingForm";
import { usePendingApplications } from "../../context/PendingApplications";

export default function FinancialSpreadingUploadPage() {
  const { company_id } = useParams();
  const [file, setFile] = useState();

  const [formData, setFormData] = useState({});
  const [companyData, setCompanyData] = useState()
  const [isFormValid, setIsFormValid] = useState(false)
  const { setLoading, setLoaderContent } = useLoader();
  const navigate = useNavigate();
  const { showToast } = useToaster()
  const { refreshPendingApplication } = usePendingApplications()

  const goToDashboard = () => {
    navigate(URL_DASHBOARD);
  };
  const { currencyOptions } = useApplicationContext();

  const handleFinancialSpreadingFormChange = (formData, isFormValid) => {
    setIsFormValid(isFormValid)
    setFormData((currentFormData) => ({
      ...currentFormData,
      ...formData,
    }));
  };

  const handleBackToHome = () => {
    setLoading(false)
    navigate(URL_DASHBOARD)
  }

  useEffect(() => {
    if (company_id) {
      setLoading(true);
      axios
        .get(
          `${API_GET_COMPANIES}/${company_id}?include_detail=false`,
          {
            withCredentials: true,
          }
        )
        .then(({ data: { data } }) => {
          const { company_name, group_name, doc_currency, out_currency, exchange_rate } = data
          setCompanyData(data);
          setFormData({
            company_name,
            group_name,
            doc_currency,
            out_currency,
            exchange_rate,
          })
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company_id]);

  const loadingContent = () => {
    return <>
      <div className="text-[#121212] text-center font-medium text-[18px] leading-[28px] w-[565px]">
        We’re processing your request, it’ll just take a few minutes. You’ll receive an email when we’re done. Feel free to stick around or head back to Home to start another task. Thanks for waiting!
      </div>
      <div className="flex flex-col align-center justify-center h-[48px] rounded-[8px] py-[12px] px-[20px] bg-gradient-to-r from-[#023972] to-[#356CA5]">
        <div className="text-[#fff] text-base font-semibold tracking-[-0.48px]" onClick={() => {
          handleBackToHome()
          refreshPendingApplication()
        }}>Back to Home</div>
      </div>
    </>
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const request = new FormData();

    let submittedFormData = { ...formData }
    if (companyData) {
      submittedFormData.company_name = companyData.company_name
      submittedFormData.group_name = companyData.group_name
    }

    Object.keys(formData).forEach((fieldName) => {
      const fieldValue = formData[fieldName];
      request.append(fieldName, fieldValue);
    });

    request.append("file", file);
    setLoaderContent(loadingContent)

    return axios
      .post(API_APPLICATIONS, request, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }).then(({ data }) => {
        refreshPendingApplication()
        navigate(`${URL_APPLICATION_VALIDATE}/${data.id}`);
      }).catch((err) => {
        console.error('err:', err)
        showToast('Unexpected error. please try again later', TOAST_TYPE.ERROR)
      }).finally(() => {
        setLoading(false);
      })
  };


  return (
    <div
      className={`w-full max-w-default px-16 relative flex flex-col items-center`}
    >
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col items-center"
      >
        <div className="flex items-center justify-between w-full">
          <div className="py-[40px] flex gap-[16px] items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={goToDashboard}
            />
            <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Create a New Company Account
            </span>
          </div>
          <button
            className="!h-[48px] !py-[12px] !px-[20px] primary w-[100px] text-[16px] font-[600] leading-[24px] tracking-[-0.48px] text-white"
            type="submit"
            disabled={
              !file || !isFormValid
            }
          >
            Next
          </button>
        </div>
        <FinancialSpreadingForm form={formData} initialValue={companyData} currencyOptions={currencyOptions} onFormChange={handleFinancialSpreadingFormChange} />
      </form>
      <div className="mt-[32px] flex flex-col gap-[8px] p-[32px] bg-white rounded-[20px] w-full max-w-[1186px]">
        <span className="leading-[20px] tracking-[-0.28px] font-[500] text-[14px]">
          Upload Financial Statement
        </span>
        <Upload
          onFileSelect={setFile}
          file={file}
          accept={[FILE_TYPE_PDF]}
          acceptLabel="1 PDF file only. Max size of 15MB"
        />
      </div>
      {file && (
        <div className="p-[32px] mt-[32px] bg-white rounded-[20px] w-full max-w-[1186px]">
          <Preview file={file} />
        </div>
      )}
    </div>
  );
}
