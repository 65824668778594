import { useState, useEffect } from "react";
import backIcon from "../../assets/icons/icon_back.svg";
import saveSuccessImg from "../../assets/images/save_success.png";
import Checkbox from "../../components/Checkbox";
import Collapse from "../../components/Collapse";
import CommonTable from "../../components/CommonTable/cop";
import GroupTable from "../../components/CommonTable/TableGroup";
import Dropdowns from "../../components/Dropdowns";
import Modal from "../../components/Modal";
import Tabs from "../../components/Tabs";
import TextInput from "../../components/TextInput";
import { useApplicationContext } from "../../context/Application";
import { financialSpreadingResultColumns, generatedColumns } from "./columns";
import { useNavigate, useParams } from "react-router-dom";
import {
  API_APPLICATIONS,
  API_CONFIRM_APPLICATION,
  URL_APPLICATION_VALIDATE,
  URL_DASHBOARD,
} from "../../constants/url";
import { useLoader } from "../../context/Loader";
import axios from "axios";
import {
  DOCUMENT_TYPE_GENERATED,
  DOCUMENT_TYPE_PROPERTY_GENERATED,
  DOCUMENT_TYPE_UPLOADED,
  DOCUMENT_TYPE_ENUM,
  TOAST_TYPE,
  UNIT_OPTIONS,
  ALL_DOCUMENT_TYPES,
  UNAUDITED_VALUE,
} from "../../constants";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import { FORMULA_GROUPING, FORMULAS_MAP } from "../../constants/formula";
import { useToaster } from "../../context/Toaster";
import { usePendingApplications } from "../../context/PendingApplications";
import ExcelJS from "exceljs";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import formatNumber from "../../utils/number/formatNumber";

const FIELDS = {
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
  unit: "unit",
};

export default function FinancialSpreadingResult() {
  const navigate = useNavigate();
  const { setLoading, isLoading } = useLoader();
  const { application_id } = useParams();
  const [applicationData, setApplicationData] = useState({});
  const [hiddenPeriods, setHiddenPeriods] = useState([]);
  const { refreshPendingApplication } = usePendingApplications();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { showToast } = useToaster();
  const [
    {
      group_name,
      company_name,
      doc_currency,
      out_currency,
      unit,
      exchange_rate,
    },
    setFormData,
  ] = useState({});

  const { currencyOptions } = useApplicationContext();

  const setHiddenPeriodByIndex = (index, checked) => {
    setHiddenPeriods((currentHiddenPeriods) => {
      const newHiddenPeriods = [...currentHiddenPeriods];

      // hidden means not checked
      newHiddenPeriods[index] = !checked;

      return newHiddenPeriods;
    });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_APPLICATIONS}/${application_id}`, {
        withCredentials: true,
      })
      .then(({ data: { data: applicationData } }) => {
        const {
          data = [],
          group_name,
          company_name,
          doc_currency,
          out_currency,
          unit,
          exchange_rate,
        } = applicationData;
        data.sort((a, b) => a.row_number - b.row_number);
        setApplicationData(applicationData);
        setFormData({
          group_name,
          company_name,
          doc_currency,
          out_currency,
          unit,
          exchange_rate: parseFloat(exchange_rate).toFixed(2),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [application_id]);

  const { data = [], metadata = {} } = applicationData;

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );

  const [showSaveModal, setShowSaveModal] = useState(false);

  const sortByOptions = [
    {
      value: "Latest to Oldest",
      label: "Latest to Oldest",
    },
    {
      value: "Oldest to Latest",
      label: "Oldest to Latest",
    },
  ];

  const toggleShowSaveModal = () => {
    setShowSaveModal((prev) => !prev);
  };

  const currentDocumentType = activeTab;
  const currentDocumentAttribute = ALL_DOCUMENT_TYPES.find(
    ({ key, formula_type = key }) => formula_type === currentDocumentType
  );

  let documentMetadata = metadata[currentDocumentType] || {};

  // generated data doesn't have metadata, so we use income statement or cash flow statement as default
  if (currentDocumentAttribute.type === DOCUMENT_TYPE_PROPERTY_GENERATED) {
    documentMetadata =
      metadata[DOCUMENT_TYPE_ENUM.INCOME_STATEMENT] ||
      metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
  }
  const { fiscal_period_order = [] } = documentMetadata;

  const displayedFiscalPeriodOrder = fiscal_period_order.filter((_, index) => {
    return !hiddenPeriods[index];
  });

  const calculateUploadedDocumentsFormula = () => {
    const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(
      ({ key }) => metadata[key]
    );
    const calculatedFormulas = calculatedDocumentTypesArr.reduce(
      (fin, { key, formula_type = key }) => {
        const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
          ...data,
          document_type: formula_type,
        }));
        return [...fin, ...currFormula];
      },
      []
    );

    const baseCalculateMetadata = calculatedDocumentTypesArr.reduce(
      (existingMetadata, { key, formula_type = key }) => {
        return {
          ...existingMetadata,
          [formula_type]: metadata[key],
        };
      },
      {
        isOldestYear: false,
      }
    );

    const result = calculateFormulaFromData(
      data,
      calculatedFormulas,
      baseCalculateMetadata
    );

    result.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
    );
    return result;
  };

  const calculatedUploadedDocuments = calculateUploadedDocumentsFormula();

  const calculateGeneratedDocumentsFormula = () => {
    const baseCalculateMetadata = ALL_DOCUMENT_TYPES.reduce(
      (existingMetadata, { key, formula_type = key }) => {
        return {
          ...existingMetadata,
          [formula_type]: true,
        };
      },
      {
        isOldestYear: false,
      }
    );

    const calculatedFormulas = DOCUMENT_TYPE_GENERATED.reduce(
      (fin, { key, formula_type = key }) => {
        const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
          ...data,
          document_type: formula_type,
        }));
        return [...fin, ...currFormula];
      },
      []
    );

    const combinedUploadedData = [...data, ...calculatedUploadedDocuments];

    const calculatedGeneratedData = calculateFormulaFromData(
      combinedUploadedData,
      calculatedFormulas,
      baseCalculateMetadata,
      {},
      {
        useFullPeriodString: true,
      }
    );

    const allData = [...combinedUploadedData, ...calculatedGeneratedData];

    allData.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, {
        numeric: true,
      })
    );
    return allData;
  };

  const combinedDocuments = calculateGeneratedDocumentsFormula();

  const resultTable = financialSpreadingResultColumns(
    displayedFiscalPeriodOrder
  );

  const generatedTable = generatedColumns(displayedFiscalPeriodOrder);

  const shownData = combinedDocuments.filter(
    ({ document_type }) => document_type === currentDocumentType
  );

  const handleConfirmApplication = async () => {
    if (isConfirmed) {
      toggleShowSaveModal();
      return;
    }

    const confirmApplicationApi = API_CONFIRM_APPLICATION.replace(
      ":id",
      application_id
    );

    axios
      .put(
        confirmApplicationApi,
        { out_currency, unit, exchange_rate },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        toggleShowSaveModal();
        refreshPendingApplication();
        setIsConfirmed(true);
      })
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
      });
  };

  const getShownPeriodsByDocumentType = (document_type) => {
    let documentMetadata = metadata[document_type] || {};
    const currentDocumentAttribute = ALL_DOCUMENT_TYPES.find(
      ({ key, formula_type: current_formula_type = key }) =>
        current_formula_type === document_type
    );

    // generated data doesn't have metadata, so we use income statement or cash flow statement as default
    if (currentDocumentAttribute.type === DOCUMENT_TYPE_PROPERTY_GENERATED) {
      documentMetadata =
        metadata[DOCUMENT_TYPE_ENUM.INCOME_STATEMENT] ||
        metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
    }
    const { fiscal_period_order = [] } = documentMetadata;

    return fiscal_period_order.filter((_, index) => {
      return !hiddenPeriods[index];
    });
  };

  const handleDownloadFile = () => {
    handleConfirmApplication().then(async () => {
      const companyInformationData = [
        ["Group Name", group_name],
        ["Company Name", company_name],
        ["Document Currency", doc_currency],
        ["Output Currency", out_currency],
        ["Exchange Rate", exchange_rate.toString()],
        ["Unit", unit],
      ];

      const DOCUMENT_DATA_MAP = combinedDocuments.reduce(
        (documentDataMap, currentData) => {
          const { document_type } = currentData;
          const currentDocumentData = documentDataMap[document_type] || [];
          currentDocumentData.push(currentData);

          return {
            ...documentDataMap,
            [document_type]: currentDocumentData,
          };
        },
        {}
      );

      const workbook = new ExcelJS.Workbook();
      ALL_DOCUMENT_TYPES.forEach(
        ({ key, formula_type = key, text, hidePeriod }) => {
          const worksheet = workbook.addWorksheet(text);

          companyInformationData.forEach((companyInfo) => {
            const sheetRow = worksheet.addRow(companyInfo).getCell(1);
            sheetRow.font = { bold: true };
            sheetRow.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "E8F4FF" },
            };
          });
          worksheet.addRow([]);
          worksheet.addRow([]);

          const displayedFiscalPeriodOrder =
            getShownPeriodsByDocumentType(formula_type);

          const extractedFiscalPeriods = displayedFiscalPeriodOrder.map(
            (periodString) => extractPeriodAndDate(periodString)
          );

          const sheetDateRow = worksheet.addRow([
            text,
            ...extractedFiscalPeriods.map(
              ({ month, year }) => `${month} ${year}`
            ),
          ]);

          sheetDateRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E8F4FF" },
          };

          sheetDateRow.font = {
            bold: true,
          };

          if (!hidePeriod) {
            const sheetPeriodRow = worksheet.addRow([
              "",
              ...extractedFiscalPeriods.map(({ period }) => `${period} months`),
            ]);

            sheetPeriodRow.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "E8F4FF" },
            };

            sheetPeriodRow.font = {
              bold: true,
            };
          }

          const sheetAuditedRow = worksheet.addRow([
            "Standard Account Name",
            ...extractedFiscalPeriods.map(
              ({ audited = UNAUDITED_VALUE }) => audited
            ),
          ]);

          sheetAuditedRow.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E8F4FF" },
          };

          sheetAuditedRow.font = {
            bold: true,
          };
        }
      );

      DOCUMENT_TYPE_UPLOADED.forEach(({ key, formula_type = key, text }) => {
        const worksheet = workbook.getWorksheet(text);

        const documentData = DOCUMENT_DATA_MAP[formula_type] || [];
        const displayedFiscalPeriodOrder =
          getShownPeriodsByDocumentType(formula_type);

        documentData.forEach(({ tp_standard_account_name, amount, bold }) => {
          const sheetRow = worksheet.addRow([
            tp_standard_account_name,
            ...displayedFiscalPeriodOrder.map((periodString) =>
              formatNumber(amount[periodString] || 0)
            ),
          ]);

          if (bold) {
            sheetRow.font = {
              bold: true,
            };

            sheetRow.eachCell((cell) => {
              cell.border = {
                top: { style: "thin", color: { argb: "000000" } }, // Black thin top border
              };
            });

            worksheet.addRow([]);
          }
        });
      });

      // Generated document types, like key ratios, are grouped
      DOCUMENT_TYPE_GENERATED.forEach(({ key, formula_type = key, text }) => {
        const worksheet = workbook.getWorksheet(text);
        const documentData = DOCUMENT_DATA_MAP[formula_type] || [];

        const groupedDocumentData = groupData(documentData);

        groupedDocumentData.forEach(({ groupTitle, data }) => {
          worksheet.addRow([groupTitle]).font = {
            bold: true,
          };

          data.forEach(({ tp_standard_account_name, amount, bold }) => {
            worksheet.addRow([
              tp_standard_account_name,
              ...displayedFiscalPeriodOrder.map((periodString) =>
                formatNumber(amount[periodString] || 0)
              ),
            ]);
          });
        });
      });

      // make all sheet's width fit to content
      ALL_DOCUMENT_TYPES.forEach(({ text }) => {
        const worksheet = workbook.getWorksheet(text);

        worksheet.columns.forEach((column) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: false }, (cell) => {
            const cellValue = cell.value ? cell.value.toString() : "";
            maxLength = Math.max(maxLength, cellValue.length);
          });
          column.width = maxLength + 2; // Add some padding to the column width
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = `${group_name}_${company_name}_${application_id}.xlsx`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    });
  };

  const handleChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleDropdownChange = (value, name) => {
    handleChange(name, value);
  };

  const groupData = (data = []) => {
    const groupings = FORMULA_GROUPING[currentDocumentType];

    const groupedData = {};

    data.forEach((datum) => {
      const { account_code } = datum;
      for (let i = 0; i < groupings.length; i++) {
        const { name, content } = groupings[i];

        const [startAccountCode, endAccountCode = startAccountCode] =
          content.split("-");

        if (
          account_code >= startAccountCode &&
          account_code <= endAccountCode
        ) {
          const currentGroupedData = groupedData[name] || {
            groupTitle: name,
            data: [],
          };
          currentGroupedData.data.push(datum);
          groupedData[name] = currentGroupedData;
          break;
        }
      }
    });

    return Object.values(groupedData);
  };

  const isSameCurrency =
    doc_currency && out_currency && doc_currency === out_currency;

  useEffect(() => {
    if (isSameCurrency) {
      handleChange(FIELDS.exchange_rate, 1.0);
    }
  }, [isSameCurrency]);

  if (isLoading) {
    return null;
  }

  const Table = ({ data = [] }) => {
    if (currentDocumentAttribute.type !== DOCUMENT_TYPE_PROPERTY_GENERATED) {
      return (
        <>
          <Collapse
            title="Sort & Filter"
            panel={
              <div className="w-full flex flex-col gap-[32px] pt-[22px]">
                <Dropdowns
                  label="Sort by"
                  data={sortByOptions}
                  defaultValue="Latest to Oldest"
                  labelClassName="text-[16px] leading-[24px] tracking-[-0.32px] font-[600] text-neutral-strong mb-[10px] block"
                />
                <div className="flex flex-col gap-[16px] items-start w-full">
                  <div className="flex justify-between items-center w-full">
                    <span className="text-[16px] leading-[24px] tracking-[-0.32px] font-[600] text-neutral-strong flex-1">
                      Show & Hide column
                    </span>
                    <div className="flex items-center justify-between gap-[20px] w-fit">
                      <span
                        className="select-none cursor-pointer text-[14px] font-[600] leading-[24px] tracking-[-0.28px] text-neutral-medium"
                        onClick={() =>
                          setHiddenPeriods(fiscal_period_order.map(() => true))
                        }
                      >
                        Clear All
                      </span>
                      <span
                        className="select-none cursor-pointer text-[14px] font-[600] leading-[24px] tracking-[-0.28px] text-primary-1"
                        onClick={() => setHiddenPeriods([])}
                      >
                        Select All
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-[16px] items-center justify-start w-full flex-wrap">
                    {fiscal_period_order.map((time, index) => (
                      <Checkbox
                        onChange={setHiddenPeriodByIndex.bind(null, index)}
                        className="min-w-[150px]"
                        title={time}
                        key={time}
                        isChecked={!hiddenPeriods[index]}
                      />
                    ))}
                  </div>
                </div>
              </div>
            }
          />
          <CommonTable
            minWidth={resultTable.minWidth}
            columns={resultTable.columns}
            rowsData={data}
            label="Financial Mapping"
            unit={unit}
            currency={out_currency}
            exchange_rate={exchange_rate}
          />
        </>
      );
    }

    const groupedData = groupData(data);

    return (
      <>
        <GroupTable
          label={currentDocumentAttribute.text}
          config={generatedTable}
          groups={groupedData}
        />
        <div className="min-h-2"></div>
      </>
    );
  };

  return (
    <div
      className={`w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[40px] flex-col gap-[16px]">
        <div className="pb-[16px] flex justify-between">
          <div className="flex gap-[16px] flex-1 items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={() =>
                navigate(`${URL_APPLICATION_VALIDATE}/${application_id}`)
              }
            />
            <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Financial Spreading Result
            </span>
          </div>
          <div className="flex gap-[20px]">
            <button
              onClick={() =>
                navigate(`${URL_APPLICATION_VALIDATE}/${application_id}`)
              }
              className="w-[100px] !h-[48px] !py-[12px] !px-[20px] default border-[1px] border-border-neutral-medium hover:border-neutral-light font-[600] leading-[24px] tracking-[-0.48px] text-[16px] flex justify-center"
            >
              Previous
            </button>
            <button
              onClick={() => handleConfirmApplication()}
              className="w-[100px] !h-[48px] !py-[12px] !px-[20px] default border-[1px] border-border-neutral-medium hover:border-neutral-light font-[600] leading-[24px] tracking-[-0.48px] text-[16px]"
            >
              Save
            </button>
            <button
              onClick={() => {
                handleDownloadFile();
              }}
              className="w-[164px] !h-[48px] !py-[12px] !px-[20px] primary font-[600] leading-[24px] tracking-[-0.48px] text-[16px] text-white p-[12px]"
            >
              Save & Download
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[32px] w-full">
        <div className="flex flex-col p-[32px] gap-[24px] bg-white max-w-[1186px] w-full rounded-[20px]">
          <div className="flex gap-[24px] w-full">
            <TextInput
              label="Group Name"
              className="flex-1"
              value={group_name}
              disabled
            />
            <TextInput
              label="Company Name"
              className="flex-1"
              value={company_name}
              disabled
            />
          </div>
          <div className="flex gap-[24px] w-full">
            <Dropdowns
              label="Document Currency"
              className="flex-1"
              defaultValue={doc_currency}
              data={currencyOptions}
              disabled
            />
            <Dropdowns
              id={FIELDS.out_currency}
              label="Output Currency"
              className="flex-1"
              defaultValue={out_currency}
              data={currencyOptions}
              onChange={handleDropdownChange}
            />
          </div>
          <div className="flex gap-[24px] w-full">
            <TextInput
              id={FIELDS.exchange_rate}
              label="Exchange Rate"
              className="flex-1"
              defaultValue={parseFloat(exchange_rate).toFixed(2)}
              value={exchange_rate}
              onChange={handleInputChange}
              disabled={isSameCurrency}
            />
            <Dropdowns
              id={FIELDS.unit}
              label="Unit"
              className="flex-1"
              defaultValue={unit}
              onChange={handleDropdownChange}
              data={UNIT_OPTIONS}
            />
          </div>
        </div>
        <div className="max-w-[1186px] w-full flex flex-col gap-[24px]">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabsLabel={ALL_DOCUMENT_TYPES}
          />
          <Table data={shownData} />
        </div>
      </div>
      {/* success modal */}
      {showSaveModal && (
        <Modal
          title={"Saved Successfully!"}
          img={saveSuccessImg}
          confirmText="Back to Home"
          onConfirm={() => navigate(URL_DASHBOARD)}
          onClose={toggleShowSaveModal}
        />
      )}
      {/* failed modal */}
      {/* {showSaveModal && <Modal
        title={"Saved failed!"}
        img={saveFailedImg}
        confirmText="Try a gain"
        titleClassname="!text-[#831919]"
        onClose={toggleShowSaveModal}
        onConfirm={toggleShowSaveModal}
      />} */}
    </div>
  );
}
