import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/Auth";
import NotFound from "../../pages/NotFound";

export default function ProtectedRoute({ allowedRoles = [] }) {
  const { user } = useAuthContext();

  if (!user || !allowedRoles.includes(user.role)) {
    return <NotFound />;
  }

  return <Outlet />;
}
