import { Fragment, useEffect, useRef, useState } from "react";
import downIcon from "../../assets/icons/icon_down.svg";
import savedIcon from "../../assets/icons/icon_saved.svg";
import failedIcon from "../../assets/icons/icon_process_failed.svg";
import upIcon from "../../assets/icons/icon_up.svg";
import { usePendingApplications } from "../../context/PendingApplications";
import { PENDING_APPLICATION_STATUS } from "../../constants/statuses";
import { useNavigate } from "react-router-dom";
import { URL_APPLICATION_VALIDATE } from "../../constants/url";
import checklistIcon from "../../assets/icons/icon_checklist.svg";
import Tooltip from "../Tooltip";

export default function PendingApplications() {
  const { pendingApplications, hasPendingApplication } =
    usePendingApplications();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen((isOpen) => !isOpen);
  const pendingApplicationsDropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (
      pendingApplicationsDropdownRef.current &&
      !pendingApplicationsDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!pendingApplications.length) {
    return null;
  }

  return (
    <div ref={pendingApplicationsDropdownRef} className="relative">
      <div
        className={`w-full border justify-evenly border-neutral-200 py-3 px-4 flex flex-row h-max items-center cursor-pointer rounded-2xl ${
          isOpen &&
          "border border-b-0 rounded-b-2xl rounded-bl-none rounded-br-none"
        }`}
        onClick={toggleDropdown}
      >
        {hasPendingApplication ? (
          <Fragment>
            <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 mr-4 border-t-white" />
            <span className="pr-3 text-primary-2 font-semibold text-[15px] leading-[20px]">
              System Processing
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <img src={checklistIcon} className="mr-4 h-6" alt="Done" />
            <span className="pr-3 text-[#45B441] font-semibold text-[15px] leading-[20px]">
              Processing Completed
            </span>
          </Fragment>
        )}
        <img src={isOpen ? upIcon : downIcon} className="h-6" alt="Toggle" />
      </div>
      {isOpen && (
        <div className="z-50 w-full absolute top-full bg-white border border-t-0 rounded-b-2xl flex flex-col w-72s p-[16px]">
          {pendingApplications.map(({ companyName, id, status }) => {
            const canProgress = status === PENDING_APPLICATION_STATUS.DONE;

            return (
              <button
                className={`flex justify-between ${
                  canProgress && "hover:bg-gray-100"
                } ${!canProgress && "cursor-default"} border-b-[1px] py-[8px]`}
                onClick={() => {
                  if (!canProgress) {
                    return;
                  }
                  setIsOpen(false);
                  navigate(`${URL_APPLICATION_VALIDATE}/${id}`);
                }}
              >
                <span className="text-neutral-strong text-[16px] font-[600]">
                  {companyName}
                </span>
                {status === PENDING_APPLICATION_STATUS.IN_PROGRESS && (
                  <Tooltip
                    tooltip="Processing"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] w-[68px]"
                  >
                    <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 border-t-white" />
                  </Tooltip>
                )}
                {status === PENDING_APPLICATION_STATUS.DONE && (
                  <Tooltip
                    tooltip="Financial Spreading Processing Completed"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] w-[121px]"
                  >
                    <img src={savedIcon} className="h-6" alt="Done" />
                  </Tooltip>
                )}
                {status === PENDING_APPLICATION_STATUS.FAILED && (
                  <Tooltip
                    tooltip="Financial Spreading Processing Failed"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] w-[106px]"
                  >
                    <img src={failedIcon} className="h-6" alt="Failed" />
                  </Tooltip>
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
