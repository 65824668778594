import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDropdownIcon } from "../../../assets/icons/arrow_drop_down.svg";

export default function TableSelect({
  data,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
  allowCustomText = false,
  onChange,
  ...props
}) {
  const { id, name = id } = props;
  const [dropDownData, setDropDownData] = useState(data);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChangeFilter = (e) => {
    const keyWords = e.target.value;
    setDropDownData(
      data.filter((item) =>
        item.label.toLowerCase().includes(keyWords.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`relative inline-block text-left ${className} `}
      ref={dropdownRef}
    >
      <div className="relative flex cursor-pointer" onClick={() => setOpen((prev) => !prev)}>
        <input
          ref={inputRef}
          id={id}
          name={id}
          defaultValue={defaultValue}
          aria-expanded={open}
          aria-haspopup="true"
          placeholder={placeholder}
          onChange={(e) => {
            if (allowCustomText) {
              onChange?.(e.target.value, name);
            }
            handleChangeFilter(e.target.value);
          }}
          autoComplete={"off"}
          className={`!px-[16px] !py-[6px] text-neutral-strong font-[600] text-[13px] leading-[16px] tracking-[-0.26px] placeholder:font-[400] ${inputClassName} overflow-ellipsis`}
        />

        <ArrowDropdownIcon color="#121212" />
      </div>
      {open && (
        <div
          className="absolute w-full z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[140px]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div
            className="p-[8px] max-h-[200px] min-w-[140px] overflow-y-auto"
            role="none"
          >
            {dropDownData.length === 0 && (
              <span
                className="block px-[12px] py-[8px] text-[13px] font-[500] leading-[16px] tracking-[-0.28px] "
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                Not found
              </span>
            )}
            {dropDownData.length > 0 &&
              dropDownData.map((item) => (
                <span
                  className="block px-[12px] py-[8px] text-[13px] font-[500] leading-[16px] tracking-[-0.28px] hover:bg-neutral-flat-hover"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  key={item.value}
                  onClick={() => {
                    onChange?.(item.value, name);
                    inputRef.current.value = item.label;
                    setOpen(false);
                  }}
                >
                  {item.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
