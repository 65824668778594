import { useEffect, useState } from "react";
import Dropdowns from "../Dropdowns";
import TextInput from "../TextInput";

const FIELDS = {
  group_name: "group_name",
  company_name: "company_name",
  doc_currency: "doc_currency",
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

const initialFormValue = REQUIRED_FIELDS.reduce((formValue, fieldName) => {
  return {
    ...formValue,
    [fieldName]: "",
  };
}, {});

export default function FinancialSpreadingForm({
  currencyOptions = [],
  onFormChange,
  form,
  initialValue,
}) {
  const handleChange = (key, value) => {
    const updatedFormData = {
      ...form,
      [key]: value,
    };
    const isFormValid = REQUIRED_FIELDS.some(
      (fieldName) => !updatedFormData[fieldName]
    );
    onFormChange?.(updatedFormData, !isFormValid);
  };

  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const handleDropdownChange = (value, name) => {
    handleChange(name, value);
  };

  const isSameCurrency =
    form[FIELDS.doc_currency] &&
    form[FIELDS.out_currency] &&
    form[FIELDS.doc_currency] === form[FIELDS.out_currency];

  useEffect(() => {
    if (isSameCurrency) {
      handleChange(FIELDS.exchange_rate, 1);
    }
  }, [isSameCurrency]);

  return (
    <div className="max-w-[1186px] w-full flex flex-col gap-[32px]">
      <div className="flex items-center justify-between gap-[24px] p-[32px] bg-white rounded-[20px]">
        <Dropdowns
          id={FIELDS.group_name}
          label="Group Name"
          placeholder="Enter Group Name"
          data={[]}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.group_name]}
          allowCustomText
          disabled={initialValue?.[FIELDS.group_name]}
          value={form[FIELDS.group_name]}
        />
        <TextInput
          id={FIELDS.company_name}
          label="Company Name"
          placeholder="Enter Company Name"
          className="flex-1"
          required
          value={form[FIELDS.company_name]}
          onChange={handleInputChange}
          referralOptions={[]}
          disabled={initialValue?.[FIELDS.company_name]}
        />
      </div>
      <div className="flex items-center justify-between gap-[24px] p-[32px] bg-white rounded-[20px]">
        <Dropdowns
          id={FIELDS.doc_currency}
          label="Document Currency"
          placeholder="Select a Currency"
          data={currencyOptions}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.doc_currency]}
          value={form[FIELDS.doc_currency]}
        />
        <Dropdowns
          id={FIELDS.out_currency}
          label="Outcome Currency"
          placeholder="Select an outcome Currency"
          data={currencyOptions}
          className="flex-1"
          required
          onChange={handleDropdownChange}
          defaultValue={form[FIELDS.out_currency]}
          value={form[FIELDS.out_currency]}
        />
        <TextInput
          id={FIELDS.exchange_rate}
          label="FX Rate"
          className="flex-1"
          placeholder="Enter FX rate"
          required
          value={form[FIELDS.exchange_rate]}
          onChange={handleInputChange}
          disabled={isSameCurrency}
        />
      </div>
    </div>
  );
}
