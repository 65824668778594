export default function generateHighlightDiv ({ width, height }) {
  const highlightDiv = document.createElement("span");
  highlightDiv.style.position = "absolute";
  highlightDiv.className =
    "border-2 border-solid border-blue-600 bg-blue-600/[.08]";
  highlightDiv.style.padding = `4px`;
  highlightDiv.style.left = `${-2}px`;
  highlightDiv.style.top = `${-4}px`;
  highlightDiv.style.width = `${width}px`;

  highlightDiv.style.height = `${height + 8}px`;
  highlightDiv.style.display = "none";

  return highlightDiv;
};