const BaseButton = ({ btnClassname = '', titleClassname = '', title, handleClick }) => {
    return (
        <button
            className={`bg-gradient-to-r from-[#023972] to-[#356CA5] p-[12px] flex items-center justify-center rounded-[8px] ${btnClassname}`}
            onClick={handleClick}
        >
            <div className={`font-[600] leading-[24px] tracking-[-0.48px] text-[16px] text-white ${titleClassname}`}>
                {title}
            </div>
        </button>
    );
};

export default BaseButton;
