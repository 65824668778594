import { useMemo, useState } from "react";
import backIcon from "../../assets/icons/icon_back.svg";
import BarChart from "../../components/BarChart";

import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/CommonTable/cop";
import { URL_DASHBOARD } from "../../constants/url";
import TeamMember from "../common/TeamMember";
import {
  mockData,
  PORTFOLIO_OVERVIEW_COLS,
  portfolioCols,
} from "./portfolioCols";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { SORT_ORDER } from "../../constants";

export default function OverallPortfolio() {
  const navigate = useNavigate();
  const [isAscCols, setIsAscCols] = useState({
    [PORTFOLIO_OVERVIEW_COLS.MONTH]: true,
    [PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_GROUP]: true,
    [PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_COMPANY]: true,
  });

  const [portfolio, setPortfolio] = useState(mockData);

  const datasets = [
    {
      label: "Group",
      data: [30, 45, 60, 40, 70, 90, 20],
      borderColor: "rgba(0, 0, 0, 0.11)",
      backgroundColor: "rgba(0, 0, 0, 0.11)",
      fill: true,
      tension: 0.4,
      hoverBackgroundColor: "#45B441",
      borderRadius: 4,
    },
    {
      label: "Company",
      data: [30, 34, 22, 16, 88, 22, 20],
      borderColor: "rgba(0, 0, 0, 0.11)",
      backgroundColor: "rgba(0, 0, 0, 0.11)",
      fill: true,
      tension: 0.4,
      hoverBackgroundColor: "#023972",
      borderRadius: 4,
    },
  ];
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        portfolio,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setPortfolio(
      sortByKey(
        portfolio,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  const customLegend = useMemo(() => {
    return (
      <div className="flex gap-[24px]">
        <div className="flex items-center gap-[4px]">
          <div className="w-[12px] h-[12px] rounded-[100px] bg-[#45B441]"></div>
          <div className="text-[#525252] text-[14px] font-[450] leading-[20px] tracking-[-0.28px]">
            Group
          </div>
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[12px] h-[12px] rounded-[100px] bg-[#023972]"></div>
          <div className="text-[#525252] text-[14px] font-[450] leading-[20px] tracking-[-0.28px]">
            Company
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="overflow-hidden px-16 relative min-h-[calc(100vh-70px-16px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Overall Portfolio
        </span>
      </div>
      <div className="flex gap-[24px] w-full">
        <div className="flex flex-col w-full gap-[24px]">
          <BarChart
            title="Team Portfolio Overview"
            datasets={datasets}
            labels={labels}
            displayLegend={false}
            customLegend={customLegend}
          />
          {/* Portfolio Overview */}
          <CommonTable
            minWidth={portfolioCols.minWidth}
            columns={portfolioCols.columns({ handleSort, isAscCols })}
            rowsData={portfolio}
            label="Portfolio Overview"
          />
        </div>
        {/* team member */}
        <TeamMember />
      </div>
      <div className="min-h-[16px]"></div>
    </div>
  );
}
