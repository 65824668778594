import iconEdit from "../../assets/icons/icon_edit_table.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import Tooltip from "../../components/Tooltip";
import defaultAvatarImage from "../../assets/images/default_avatar.png";
import SortIconTable from "../../components/SortIconTable";

export const mockData = [
  {
    id: 1,
    name: "Annette Black",
    avatarName: {
      avatar: defaultAvatarImage,
      name: "Annette Black",
    },
    email: "1michael.mitc@example.com",
  },
  {
    id: 1,
    name: "TAnnette Black",
    avatarName: {
      avatar: defaultAvatarImage,
      name: "TAnnette Black",
    },
    email: "2michael.mitc@example.com",
  },
  {
    id: 1,
    name: "KKKAnnette Black",
    avatarName: {
      avatar: defaultAvatarImage,
      name: "KKKAnnette Black",
    },
    email: "4michael.mitc@example.com",
  },
];

export const MEMBER_COLS = {
  NAME: "name",
  EMAIL: "email",
};

export const memberCols = {
  minWidth: 320 + 240 + 120,
  columns: ({ handleEdit, handleSort, isAscCols }) => [
    {
      key: "avatarName",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[40px] bg-primary-50 rounded-tl-[8px] min-w-[320px] sticky left-0"
          onClick={() => handleSort(MEMBER_COLS.NAME)}
        >
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-card-on-surface">
            Member
            <SortIconTable isAsc={isAscCols[MEMBER_COLS.NAME]} />
          </div>
        </th>
      ),
      renderRow: ({ avatarName }) => (
        <td className="bg-white !h-[51px] !p-0 min-w-[320px] flex justify-center items-center sticky left-0">
          <img
            src={defaultAvatarImage}
            alt="Profile"
            width={32}
            height={32}
            className="w-[32px] h-[32px] rounded-full cursor-pointer"
          />
          <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-card-on-surface">
            {avatarName.name}
          </div>
        </td>
      ),
    },
    {
      key: "email",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[40px] bg-primary-50 text-Gray-500 w-[240px]"
          onClick={() => handleSort(MEMBER_COLS.EMAIL)}
        >
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-card-on-surface">
            Email
            <SortIconTable isAsc={isAscCols[MEMBER_COLS.EMAIL]} />
          </div>
        </th>
      ),
      renderRow: ({ email }) => (
        <td className="bg-white !h-[51px] !p-0 w-[240px]">
          <div className="!px-[9.5px] !text-[13px] flex text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
            {email}
          </div>
        </td>
      ),
    },
    {
      key: "action",
      renderHeader: () => (
        <th className="!p-[0] !h-[40px] min-w-[180px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0">
          <div className="flex justify-center">Actions</div>
        </th>
      ),
      renderRow: ({ index }) => (
        <td className="bg-white sticky right-0 min-w-[180px] !h-[51px] !py-0 !px-[12px]">
          <div className="flex justify-center gap-[10px] items-center">
            <Tooltip
              tooltip="Edit"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px]"
            >
              <img
                src={iconEdit}
                className="w-[20px] cursor-pointer"
                alt=""
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip
              tooltip="Delete"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px] !ml-[-20px]"
            >
              <img src={iconTrash} className="w-[20px] cursor-pointer" alt="" />
            </Tooltip>
          </div>
        </td>
      ),
    },
  ],
};
