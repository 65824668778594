import TableInput from "../../components/TableElements/Input";
import Tooltip from "../../components/Tooltip";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import TableSelect from "../../components/TableElements/Select";
import formatNumber from "../../utils/number/formatNumber";
import formatCurrency from "../../utils/currency/formatCurrency";
import iconInfoTooltip from "../../assets/icons/icon_info_tooltip.svg";

export const financialSpreadingResultColumns = (fiscal_period_order = []) => ({
  minWidth: 200 + fiscal_period_order.length * 120,
  columns: [
    {
      key: "standardAccountName",
      renderHeader: () => (
        <th className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] w-[248px] sticky left-0 ">
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-[1px] border-r-card-on-surface">
            Standard Account Name
          </div>
        </th>
      ),
      renderRow: ({ tp_standard_account_name, bold }) => (
        <td
          className={`${
            bold ? "bg-primary-50" : "bg-white"
          } sticky left-0 !h-[35px] !p-0 w-[248px] `}
        >
          <div
            className={`!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start ${
              bold ? "font-bold" : "text-neutral-strong"
            } leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface`}
          >
            {tp_standard_account_name}
          </div>
        </td>
      ),
    },
    ...fiscal_period_order.map((periodString) => ({
      key: periodString,
      renderHeader: () => {
        const {
          month,
          year,
          period = 12,
          audited,
        } = extractPeriodAndDate(periodString);

        let displayedPeriod = year;

        if (month) {
          displayedPeriod = `${month} ${displayedPeriod}`;
        }
        return (
          <th className="!p-[0] !h-[51px] bg-primary-50 text-Gray-500">
            <div className="min-w-[120px] !pr-[9.5px] text-end flex justify-end">
              <div className="flex flex-col items-center justify-end">
                <span className="font-[600] text-[13px] leading-[20px]">
                  {displayedPeriod}
                </span>
                <span className="font-[500] text-[12px] leading-[16px] text-end w-full">
                  {period} months
                </span>
                <span className="font-[500] text-[12px] leading-[16px] text-end w-full">
                  {audited}
                </span>
              </div>
            </div>
          </th>
        );
      },
      renderRow: ({ amount, bold, unit, exchange_rate = 1, currency }) => (
        <td
          className={`${
            bold ? "bg-primary-50" : "bg-white"
          } sticky left-0 !h-[35px] !p-0 min-w-[120px]`}
        >
          <div
            className={`!px-[9.5px] !text-[13px] flex justify-end ${
              bold ? "font-bold" : "text-neutral-strong"
            } leading-[20px] tracking-[-0.28px] font-[400] w-full`}
          >
            {formatCurrency(
              (amount[periodString] || 0) * exchange_rate,
              currency
            )}
            {unit && ` ${unit}`}
          </div>
        </td>
      ),
    })),
  ],
});

export const financialSpreadingResultColumnsCompany = ({
  fiscalPeriods,
  isEdit = false,
  standardAccountOptions = [],
}) => {
  return {
    minWidth: isEdit
      ? 248 + 248 + 112 * fiscalPeriods.length
      : 320 + 112 * fiscalPeriods.length,
    laptopMinWidth: isEdit
      ? 140 + 140 + 112 * fiscalPeriods.length
      : 140 + 112 * fiscalPeriods.length,
    columns: [
      isEdit && {
        key: "client_account_name",
        renderHeader: () => (
          <th className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] 2xl:w-[248px] xl:w-[140px] sticky left-0 ">
            <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-[1px] border-r-card-on-surface">
              Client Account Name
            </div>
          </th>
        ),
        renderRow: ({ client_account_name }) => (
          <td className="bg-white sticky left-0 !h-[35px] !p-0 2xl:w-[248px] xl:w-[140px] ">
            <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
              {client_account_name}
            </div>
          </td>
        ),
        renderEditRow: ({
          editingRows,
          id,
          client_account_name,
          handleEditRecordValue,
        }) =>
          editingRows[id] ? (
            <td className="bg-primary-50 sticky left-0 !h-[35px] !p-0 !px-[12px] !text-[13px] !leading-[16px] ">
              <div className="2xl:max-w-[248px] xl:max-w-[140px]!px-[12px]">
                <TableInput
                  className="w-full"
                  name="client_account_name"
                  handleChange={handleEditRecordValue}
                />
              </div>
            </td>
          ) : (
            <td className="bg-white sticky left-0 !h-[35px] !p-0 !text-[13px] !leading-[16px]">
              <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] xl:w-[140px] 2xl:w-[248px] !px-[12px]">
                {client_account_name}
              </div>
            </td>
          ),
      },
      {
        key: "tp_standard_account_name",
        renderHeader: () => (
          <th
            className={`!p-[0] !h-[51px] bg-primary-50 sticky left-0 min-w-[140px] ${
              !isEdit
                ? "rounded-tl-[8px] min-w-[140px]"
                : "2xl:w-[248px] xl:w-[140px]"
            }`}
          >
            <div className="text-Gray-500 h-full min:w-[248px] !px-[12px] text-[13px] font-[600] leading-[16px] flex justify-start items-center w-full border-r-[1px] border-r-card-on-surface">
              Standard Account Name
            </div>
          </th>
        ),
        renderRow: ({ tp_standard_account_name, handleSetEditingRows, id }) => (
          <td
            className={`bg-white sticky !h-[35px] !p-0 ${
              isEdit
                ? "xl:left-[140px] 2xl:left-[248px] 2xl:w-[248px] xl:w-[140px]"
                : "min:w-[248px]"
            }`}
          >
            <div
              className="!px-[12px] h-full !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface"
              onClick={() =>
                isEdit ? handleSetEditingRows(id, false) : () => {}
              }
            >
              {tp_standard_account_name}
            </div>
          </td>
        ),
        renderEditRow: ({
          tp_standard_account_name,
          handleEditRecordValue,
        }) => (
          <td className="bg-primary-50 sticky xl:left-[140px] 2xl:left-[248px] !h-[35px] !p-0 z-10 min:w-[248px]">
            <div className="w-full !px-[12px] !text-[13px]">
              <TableSelect
                defaultValue={tp_standard_account_name}
                data={standardAccountOptions}
                className="w-full"
                name="tp_standard_account_name"
                handleChange={handleEditRecordValue}
              />
            </div>
          </td>
        ),
      },
      ...fiscalPeriods.map((item, index) => ({
        key: item,
        renderHeader: () => {
          const header = extractPeriodAndDate(item);
          return (
            <th
              className={`!p-[0] !h-[51px] bg-primary-50 text-Gray-500 xl:left-[140px] 2xl:left-[248px] ${
                index === fiscalPeriods.length - 1 && !isEdit
                  ? "rounded-tr-[8px]"
                  : ""
              }`}
            >
              <div className="min-w-[120px] !pr-[9.5px] text-end flex justify-end">
                <div className="flex flex-col items-center justify-end">
                  <span className="font-[600] text-[13px] leading-[20px]">
                    {`${header.month} ${header.year}`}
                  </span>
                  <span className="font-[500] text-[12px] leading-[16px] text-end w-full">
                    {header.audited}
                  </span>
                </div>
              </div>
            </th>
          );
        },
        renderRow: (_) => (
          <td className="bg-white !h-[35px] !p-0">
            <div
              className="!pl-[12px] !pr-[9.5px] !text-[13px] flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] min-w-[112px]"
              onClick={
                isEdit ? () => _.handleSetEditingRows(_.id, false) : () => {}
              }
            >
              {_[item]?.toLocaleString()}
            </div>
          </td>
        ),
        renderEditRow: (_) => (
          <td className="bg-primary-50 !h-[35px] !p-0 ">
            <div className="min-w-[112px] !pl-[12px] !pr-[26px] !text-[13px]">
              <TableInput
                defaultValue={_[item]}
                className="w-full"
                name={item}
                handleChange={_.handleEditRecordValue}
                type="number"
              />
            </div>
          </td>
        ),
      })),
      isEdit && {
        key: "action",
        renderHeader: () => (
          <th className="!p-[0] !h-[56px] max-w-[120px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0">
            <div className="flex justify-center">Actions</div>
          </th>
        ),
        renderRow: ({ index, handleAddMoreColumn, handleRemoveRw, id }) => (
          <td className="bg-white sticky right-0 w-[112px] !h-[35px] !py-0 !px-[12px]">
            <div className="flex justify-center gap-[10px] items-center">
              <Tooltip
                tooltip="Add row below"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px]"
              >
                <img
                  src={iconPlusGreen}
                  onClick={() => handleAddMoreColumn(index + 1)}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
              <Tooltip
                tooltip="Delete this row"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px]"
              >
                <img
                  src={iconTrash}
                  onClick={() => {
                    handleRemoveRw(id);
                  }}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
            </div>
          </td>
        ),
        renderEditRow: ({
          handleRemoveRw,
          handleCancelEditingRow,
          editingRows,
          handleEditRecord,
          id,
          index,
        }) => (
          <td className="bg-primary-50 sticky right-0 w-[112px] !h-[35px] !py-0 !px-[12px]">
            <div className="flex justify-center gap-[10px] items-center">
              <Tooltip
                tooltip="Save"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[39px] !w-[85px]"
              >
                <img
                  src={iconSave}
                  className="w-[20px] cursor-pointer"
                  alt=""
                  onClick={handleEditRecord}
                />
              </Tooltip>
              <Tooltip
                tooltip="Cancel"
                tooltipClassname="flex justify-center items-center p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[39px] !w-[85px]"
              >
                <img
                  onClick={() => {
                    if (editingRows?.[id]) {
                      handleRemoveRw(index);
                      return;
                    }
                    handleCancelEditingRow(id);
                  }}
                  src={iconCancel}
                  className="w-[20px] cursor-pointer"
                  alt=""
                />
              </Tooltip>
            </div>
          </td>
        ),
      },
    ].filter((item) => !!item),
  };
};
export const keyRatiosColumns = {
  minWidth: 498 + 120 * 5,
  renderHeader: () => (
    <>
      <th className="!p-[0] !h-[35px] bg-primary-50 rounded-tl-[8px] w-[498px] sticky left-0 ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-[1px] border-r-card-on-surface">
          Key Ratio
        </div>
      </th>
      <th className="!p-[0] !h-[35px] bg-primary-50 min-w-[120px] ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
          Mar 2025
        </div>
      </th>
      <th className="!p-[0] !h-[35px] bg-primary-50 min-w-[120px] ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
          Mar 2024
        </div>
      </th>
      <th className="!p-[0] !h-[35px] bg-primary-50 min-w-[120px] ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
          Mar 2023
        </div>
      </th>
      <th className="!p-[0] !h-[35px] bg-primary-50 min-w-[120px] ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
          Mar 2022
        </div>
      </th>
      <th className="!p-[0] !h-[35px] bg-primary-50 rounded-tr-[8px] min-w-[120px] ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
          Mar 2021
        </div>
      </th>
    </>
  ),
  renderTitle: (title, index) => (
    <>
      <td className="bg-white sticky left-0 !h-[35px] !p-0 w-[498px] sticky left-0 ">
        {index !== 0 && <div className="h-[20px]" />}
        <div className="h-[35px] !px-[12px] align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={title} /> */}
          {title}
        </div>
      </td>
    </>
  ),
  renderRow: (row) => (
    <>
      <td className="bg-white !h-[35px] !p-0 w-[498px] sticky left-0 ">
        <div className="!px-[32px] h-full align-middle !text-[13px] items-center flex justify-start gap-[12px] text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={row["label"]} /> */}
          {row["label"]}
          <Tooltip
            tooltip="Cash Flow from Operations – Capital Expenditure (Purchase of PP&E, Leasehold Improvements, Fixtures & Fittings, Vehicles, Other CAPEX)
If Cash Flow Statement is unavailable, Cash Flow from Operations is estimated as: Net Profit + Non-Cash Adjustments + Working Capital Changes – Capital Expenditure"
          >
            <img src={iconInfoTooltip} alt="iconInfoTooltip" />
          </Tooltip>
        </div>
      </td>
      <td className="bg-white !h-[35px] !p-0 w-[160px]">
        <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
          {row["mar2025"]}{" "}
        </div>
      </td>
      <td className="bg-white !h-[35px] !p-0 w-[160px]">
        <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
          {row["mar2024"]}
        </div>
      </td>
      <td className="bg-white !h-[35px] !p-0 w-[160px]">
        <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
          {row["mar2023"]}
        </div>
      </td>
      <td className="bg-white !h-[35px] !p-0 w-[160px]">
        <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
          {row["mar2022"]}
        </div>
      </td>
      <td className="bg-white !h-[35px] !p-0 w-[160px]">
        <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
          {row["mar2021"]}
        </div>
      </td>
    </>
  ),
};

export const generatedColumns = (fiscal_period_order = []) => ({
  minWidth: 498 + 120 * 5,
  renderHeader: () => (
    <>
      <th className="!p-[0] !h-[35px] bg-primary-50 rounded-tl-[8px] w-[498px] sticky left-0 ">
        <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center w-full border-r-[1px] border-r-card-on-surface">
          Key Ratio
        </div>
      </th>
      {fiscal_period_order.map((periodString) => {
        const { month, year } = extractPeriodAndDate(periodString);

        let displayedPeriod = year;

        if (month) {
          displayedPeriod = `${month} ${displayedPeriod}`;
        }

        return (
          <th
            className="!p-[0] !h-[35px] bg-primary-50 min-w-[120px]"
            key={periodString}
          >
            <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center justify-end w-full">
              {displayedPeriod}
            </div>
          </th>
        );
      })}
    </>
  ),
  renderTitle: (title, index) => (
    <>
      <td className="bg-white sticky left-0 !h-[35px] !p-0 w-[498px] sticky left-0 ">
        {index !== 0 && <div className="h-[20px]" />}
        <div className="font-bold h-[35px] !px-[12px] align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={title} /> */}
          {title}
        </div>
      </td>
    </>
  ),
  renderRow: ({
    tp_standard_account_name,
    description,
    amount,
    format = formatNumber,
  }) => (
    <>
      <td className="bg-white !h-[35px] !p-0 w-[498px] sticky left-0 ">
        <div className="!px-[32px] h-full align-middle !text-[13px] items-center flex justify-start gap-[12px] text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full border-r-[1px] border-r-card-on-surface">
          {/* <Checkbox title={row["label"]} /> */}
          {tp_standard_account_name}
          {description && (
            <Tooltip tooltip={description}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="currentColor"
                class="w-[16px] h-[17px] text-gray-500 hover:text-black transition duration-300"
              >
                <path d="M7.33398 5.16683H8.66732V6.50016H7.33398V5.16683ZM7.33398 7.8335H8.66732V11.8335H7.33398V7.8335ZM8.00065 1.8335C4.32065 1.8335 1.33398 4.82016 1.33398 8.50016C1.33398 12.1802 4.32065 15.1668 8.00065 15.1668C11.6807 15.1668 14.6673 12.1802 14.6673 8.50016C14.6673 4.82016 11.6807 1.8335 8.00065 1.8335ZM8.00065 13.8335C5.06065 13.8335 2.66732 11.4402 2.66732 8.50016C2.66732 5.56016 5.06065 3.16683 8.00065 3.16683C10.9407 3.16683 13.334 5.56016 13.334 8.50016C13.334 11.4402 10.9407 13.8335 8.00065 13.8335Z" />
              </svg>
            </Tooltip>
          )}
        </div>
      </td>
      {fiscal_period_order.map((periodString) => {
        return (
          <td className="bg-white !h-[35px] !p-0 w-[160px]" key={periodString}>
            <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
              {format(amount[periodString]) || "-"}
            </div>
          </td>
        );
      })}
    </>
  ),
});
