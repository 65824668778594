import SortIconTable from "../../components/SortIconTable";

export const mockData = [
  {
    id: 1,
    month: "Aug 2024",
    number_of_group: 10,
    number_of_company: 3,
  },
  {
    id: 2,
    month: "Aug 2024",
    number_of_group: 14,
    number_of_company: 7,
  },
];

export const PORTFOLIO_OVERVIEW_COLS = {
  MONTH: "month",
  NUMBER_OF_GROUP: "number_of_group",
  NUMBER_OF_COMPANY: "number_of_company",
};

export const portfolioCols = {
  minWidth: 180 * 3,
  columns: ({ handleSort, isAscCols }) => [
    {
      key: "month",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] max-w-[380px]"
          onClick={() => handleSort(PORTFOLIO_OVERVIEW_COLS.MONTH)}
        >
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center border-r-card-on-surface">
            Month
            <SortIconTable isAsc={isAscCols[PORTFOLIO_OVERVIEW_COLS.MONTH]} />
          </div>
        </th>
      ),
      renderRow: ({ month }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {month}
          </div>
        </td>
      ),
    },
    {
      key: "number_of_group",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 max-w-[380px]"
          onClick={() => handleSort(PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_GROUP)}
        >
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center border-r-card-on-surface">
            Number of Group
            <SortIconTable
              isAsc={isAscCols[PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_GROUP]}
            />
          </div>
        </th>
      ),
      renderRow: ({ number_of_group }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {number_of_group}
          </div>
        </td>
      ),
    },
    {
      key: "number_of_company",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tr-[8px] max-w-[380px]"
          onClick={() => handleSort(PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_COMPANY)}
        >
          <div className="text-Gray-500 h-full !px-[12px] text-[13px] font-[600] leading-[16px] flex items-center border-r-card-on-surface">
            Number of Company
            <SortIconTable
              isAsc={isAscCols[PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_COMPANY]}
            />
          </div>
        </th>
      ),
      renderRow: ({ number_of_company }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[12px] h-full align-middle !text-[13px] items-center flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {number_of_company}
          </div>
        </td>
      ),
    },
  ],
};
