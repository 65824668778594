import { useEffect, useState } from "react";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import iconAdditionOrSubtraction from "../../assets/icons/icon_table_addition_or_subtraction.svg";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import tableDateDropDown from "../../assets/icons/table_date_dropdown.png";
import DatePicker from "../../components/DatePicker";
import Popover from "../../components/Popover";
import { AUDITED_OPTIONS, PERIOD_MONTHS_OPTIONS } from "../../constants";
import Dropdowns from "../../components/Dropdowns";
import TableInput from "../../components/TableElements/Input";
import TableSelect from "../../components/TableElements/Select";
import Tooltip from "../../components/Tooltip";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import formatNumber from "../../utils/number/formatNumber";

const FIELDS = {
  client_account_name: "client_account_name",
  tp_standard_account_name: "tp_standard_account_name",
  amount: "amount",
  account_code: "account_code",
};

export default function FinancialSpreadingValidateTable({
  name,
  minWidth,
  fiscalPeriods = [],
  data,
  documentType,
  standardAccountOptions,
  onInvertClick,
  onAddRow,
  onDeleteRow,
  onSaveRow,
  canChangeDate = false,
  onDateChange,
  onPeriodChange,
  onAuditedChange,
  onSave,
  summary,
  content,
  calculatedData,
  onRowHover,
  confirmState,
  onConfirm,
  onResetConfirm,
}) {
  const summaryData = calculateFormulaFromData(
    calculatedData,
    summary,
    {},
    {
      document_type: documentType,
    }
  );

  const shownContentRanges = content.split(",");
  const [editingRows, setEditingRows] = useState({});
  const [rowData, setRowData] = useState([]);
  const [showPopovers, setShowPopovers] = useState();

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const closePopover = () => {
    setShowPopovers("");
  };

  const handleOpenPopover = (fiscalPeriod) => {
    setShowPopovers(fiscalPeriod);
  };

  const handleSetEditingRows = (index, isNewRow) => {
    setEditingRows((prev) => ({
      ...prev,
      [index]: isNewRow,
    }));
  };

  const handleCancelEditingRow = (index) => {
    setEditingRows((prev) => {
      return {
        ...prev,
        [index]: undefined,
      };
    });
  };

  const handleAddRow = (index) => {
    onAddRow?.(index);
    handleSetEditingRows(index + 1, true);
  };

  const handleRemoveRow = (index) => {
    onDeleteRow?.(index);
    handleCancelEditingRow(index);
  };

  const handleSaveRow = (index) => {
    const savedRow = rowData[index];

    onSaveRow(index, savedRow);

    setEditingRows((prev) => {
      return {
        ...prev,
        [index]: undefined,
      };
    });

    onResetConfirm(documentType, name);
  };

  const handleChange = (index, key, value) => {
    setRowData((currentData) => {
      const currentRowData = currentData[index] || {};

      const newRowData = {
        ...currentRowData,
        [key]: value,
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    });
  };
  const onChange = (index, event) => {
    handleChange(index, event.target.name, event.target.value);
  };

  const onDropdownChange = (index, value, key) => {
    handleChange(index, key, value);
  };

  const onAmountChange = (index, period, event) => {
    setRowData((currentData) => {
      const currentRowData = currentData[index] || {};
      const currentAmountData = currentRowData[FIELDS.amount] || {};

      const newRowData = {
        ...currentRowData,
        [FIELDS.amount]: {
          ...currentAmountData,
          [period]: parseFloat(event.target.value),
        },
      };

      const newData = [...currentData];
      newData[index] = newRowData;
      return newData;
    });
  };

  const actions = [
    onInvertClick && {
      tooltip: "Invert number sign (+/-)",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]",
      Component: ({ index }) => (
        <button onClick={onInvertClick.bind(null, index)}>
          <img
            src={iconAdditionOrSubtraction}
            className="w-[20px] cursor-pointer"
            alt="Invert value"
          />
        </button>
      ),
    },
    onAddRow &&
      onSaveRow && {
        tooltip: "Add row below",
        className:
          "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px]",
        Component: ({ index }) => (
          <button onClick={handleAddRow.bind(null, index)}>
            <img
              src={iconPlusGreen}
              className="w-[20px] cursor-pointer"
              alt="Add New Row"
            />
          </button>
        ),
      },
    onDeleteRow && {
      tooltip: "Delete this row",
      className:
        "flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px]",
      Component: ({ index }) => (
        <button onClick={handleRemoveRow.bind(null, index)}>
          <img
            src={iconTrash}
            className="w-[20px] cursor-pointer"
            alt="Delete row"
          />
        </button>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col">
        <span className="leading-[28px] tracking-[-0.6px] font-[700] text-[16px] text-black">
          {name}
        </span>
        <div className="overflow-x-auto">
          <table
            className="table-fixed"
            style={{
              minWidth: minWidth,
            }}
          >
            <thead className="bg-primary-50">
              <tr className="border-b-[1px] border-b-card-on-surface relative min-w-full">
                <th className="!p-[0] !px-[12px] !h-[56px] text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px] xl:w-[140px] 2xl:w-[248px] sticky left-0">
                  <div className="flex items-center gap-[2px] w-full">
                    Client Account Name
                  </div>
                </th>
                <th className="!p-[0] !h-[56px] text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50 xl:w-[140px] 2xl:w-[248px] sticky xl:left-[140px] 2xl:left-[248px] z-10">
                  <div className="flex justify-start float-right w-full !px-[12px]">
                    <span>Standard Account Name</span>
                  </div>
                </th>
                {fiscalPeriods.map((fiscalPeriod) => {
                  const {
                    month,
                    year,
                    period = 12,
                    monthInt,
                    audited,
                  } = extractPeriodAndDate(fiscalPeriod);

                  let displayedPeriod = year;

                  if (month) {
                    displayedPeriod = `${month} ${displayedPeriod}`;
                  }

                  if (canChangeDate) {
                    return (
                      <th className="relative !h-[56px] !p-[0] w-[112px]">
                        <button
                          className="bg-primary-50 "
                          onClick={handleOpenPopover.bind(null, fiscalPeriod)}
                        >
                          <div className="w-[112px] !px-[8px] text-end flex justify-end">
                            <div className="flex items-start gap-[2px]">
                              <div className="flex flex-col items-center justify-end">
                                <span className="font-[600] text-[13px] leading-[20px] text-primary-2">
                                  {displayedPeriod}
                                </span>
                                <span className="font-[500] text-[12px] leading-[16px] text-primary-2 text-end w-full">
                                  {period} months
                                </span>
                                <span className="font-[500] text-[12px] leading-[16px] text-primary-2 text-end w-full">
                                  {audited}
                                </span>
                              </div>
                              <div className="w-[16px] h-[16px] flex items-center justify-center">
                                <img
                                  src={tableDateDropDown}
                                  alt=""
                                  className="w-[10px] h-[5px]"
                                />
                              </div>
                            </div>
                          </div>
                        </button>
                        {showPopovers === fiscalPeriod && (
                          <Popover
                            closePopover={closePopover}
                            className="!absolute left-0 top-[56px]"
                          >
                            <DatePicker
                              startingYear={year}
                              startingMonth={monthInt}
                              onDateChange={onDateChange.bind(
                                null,
                                fiscalPeriod
                              )}
                            />
                            <Dropdowns
                              label="Select number of month"
                              defaultValue={period || 12}
                              inputClassName="!text-[14px] !leading-[20px] !py-[8px] !px-[16px]"
                              data={PERIOD_MONTHS_OPTIONS}
                              onChange={onPeriodChange.bind(null, fiscalPeriod)}
                            />
                            <Dropdowns
                              label="Type of accounts"
                              defaultValue={audited}
                              inputClassName="!text-[14px] !leading-[20px] !py-[8px] !px-[16px]"
                              className=""
                              data={AUDITED_OPTIONS}
                              onChange={onAuditedChange.bind(
                                null,
                                fiscalPeriod
                              )}
                            />
                          </Popover>
                        )}
                      </th>
                    );
                  }

                  return (
                    <th className="!p-[0] !h-[56px] bg-primary-50 text-Gray-500">
                      <div className="w-[112px] !pr-[26px] text-end flex justify-end">
                        <div className="flex flex-col items-center justify-end">
                          <span className="font-[600] text-[13px] leading-[20px]">
                            {displayedPeriod}
                          </span>
                          {period && (
                            <span className="font-[500] text-[12px] leading-[16px] text-end w-full">
                              {period} months
                            </span>
                          )}
                          <span className="font-[500] text-[12px] leading-[16px] text-end w-full">
                            {audited}
                          </span>
                        </div>
                      </div>
                    </th>
                  );
                })}
                {actions.length > 0 && (
                  <th className="!p-[0] !h-[56px] max-w-[112px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0">
                    <div className="flex justify-center">Actions</div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rowData.map(
                (
                  {
                    client_account_name,
                    tp_standard_account_name,
                    amount,
                    document_type,
                    row_number,
                  },
                  index
                ) => {
                  if (!data[index]) {
                    return null;
                  }
                  const { account_code } = data[index];

                  if (document_type !== documentType) {
                    return null;
                  }

                  for (let i = 0; i < shownContentRanges.length; i++) {
                    const range = shownContentRanges[i];
                    const [
                      startAccountCode,
                      endAccountCode = startAccountCode,
                    ] = range.split("-");

                    if (
                      account_code &&
                      (account_code < startAccountCode ||
                        account_code > endAccountCode)
                    ) {
                      return null;
                    }
                  }

                  const isEditing = editingRows[index] !== undefined;

                  if (isEditing && onSaveRow) {
                    return (
                      <tr
                        key={`${row_number}_${index}`}
                        onMouseEnter={() => onRowHover(index)}
                      >
                        <td className="bg-primary-50 sticky left-0 !h-[35px] !p-0 !px-[12px] !text-[13px] !leading-[16px] ">
                          <div className="max-w-[248px] !px-[12px]">
                            <TableInput
                              id={FIELDS.client_account_name}
                              className="w-full"
                              defaultValue={client_account_name}
                              onChange={onChange.bind(null, index)}
                            />
                          </div>
                        </td>
                        <td className="bg-primary-50 sticky xl:left-[140px] 2xl:left-[248px] !h-[35px] !p-0 z-10">
                          <div className="w-full !px-[12px] !text-[13px]">
                            <TableSelect
                              id={FIELDS.account_code}
                              defaultValue={tp_standard_account_name}
                              data={standardAccountOptions}
                              className="w-full"
                              onChange={(newAccountCode, key) => {
                                onDropdownChange(index, newAccountCode, key);

                                const newTpStandardAccountName =
                                  standardAccountOptions.find(
                                    ({ value }) => value === newAccountCode
                                  )?.label;
                                onDropdownChange(
                                  index,
                                  newTpStandardAccountName,
                                  FIELDS.tp_standard_account_name
                                );
                              }}
                            />
                          </div>
                        </td>
                        {fiscalPeriods.map((fiscalPeriod) => (
                          <td
                            className="bg-primary-50 !h-[35px] !p-0 w-[112px]"
                            key={fiscalPeriod}
                          >
                            <div className="w-[112px] !pl-[12px] !pr-[26px] !text-[13px]">
                              <TableInput
                                defaultValue={amount[fiscalPeriod] || ""}
                                className="w-full"
                                onChange={onAmountChange.bind(
                                  null,
                                  index,
                                  fiscalPeriod
                                )}
                              />
                            </div>
                          </td>
                        ))}
                        <td className="bg-primary-50 sticky right-0 w-[112px] !h-[35px] !py-0 !px-[12px]">
                          <div className="flex justify-center gap-[10px] items-center">
                            <button onClick={handleSaveRow.bind(null, index)}>
                              <img
                                src={iconSave}
                                className="w-[20px] cursor-pointer"
                                title="Save"
                                alt="Save"
                              />
                            </button>
                            <img
                              onClick={() => {
                                if (editingRows[index]) {
                                  handleRemoveRow(index);
                                  return;
                                }
                                handleCancelEditingRow(index);
                              }}
                              src={iconCancel}
                              className="w-[20px]  cursor-pointer"
                              alt="Cancel"
                              title="Cancel"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr
                      key={`${client_account_name}_${index}`}
                      onMouseEnter={() => onRowHover(index)}
                    >
                      <td
                        className="bg-white sticky left-0 !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px] xl:w-[140px] 2xl:w-[248px]"
                        onClick={() => handleSetEditingRows(index, false)}
                      >
                        <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full">
                          {client_account_name}
                        </div>
                      </td>
                      <td
                        className="bg-white sticky xl:left-[140px] 2xl:left-[248px] !h-[35px] !p-0 xl:w-[140px] 2xl:w-[248px]"
                        onClick={() => handleSetEditingRows(index, false)}
                      >
                        <div className="flex justify-start text-neutral-strong !px-[12px] !text-[13px] leading-[20px] tracking-[-0.28px] font-[400] w-full">
                          {tp_standard_account_name}
                        </div>
                      </td>
                      {fiscalPeriods.map((fiscalPeriod) => (
                        <td
                          className="bg-white !h-[35px] !p-0 w-[112px]"
                          onClick={() => handleSetEditingRows(index, false)}
                          key={fiscalPeriod}
                        >
                          <div className="!pl-[12px] !pr-[26px] !text-[13px] flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[112px]">
                            {formatNumber(amount[fiscalPeriod]) || "-"}
                          </div>
                        </td>
                      ))}
                      {actions.length > 0 && (
                        <td
                          id="action"
                          className="bg-white sticky right-0 w-[112px] !h-[35px] !py-0 !px-[12px]"
                          key={index}
                        >
                          <div className="flex justify-center gap-[10px] items-center">
                            {actions.map(
                              ({ tooltip, className, Component }) => (
                                <Tooltip
                                  tooltip={tooltip}
                                  tooltipClassname={className}
                                >
                                  <Component index={index} />{" "}
                                </Tooltip>
                              )
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
          {summaryData.length > 0 && (
            <table
              className="border-separate table-fixed border-[1px] border-card-on-surface rounded-[8px] border-spacing-0"
              style={{
                minWidth: minWidth,
              }}
            >
              <thead className="">
                <tr>
                  <th className="text-Gray-500 text-[13px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px] xl:w-[280px] 2xl:w-[496px] sticky left-0">
                    <div className="">
                      Please Confirm the Accuracy of the Calculated Figures
                    </div>
                  </th>
                  {fiscalPeriods.map(() => (
                    <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[112px]"></th>
                  ))}
                  <th className="max-w-[112px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0"></th>
                </tr>
              </thead>
              <tbody className="">
                {summaryData.map(
                  (
                    { tp_standard_account_name, amount, account_code },
                    index
                  ) => {
                    const isConfirmed = confirmState[account_code];

                    return (
                      <tr>
                        <td className="border-t-[1px] border-card-on-surface sticky left-0 !h-[35px] !py-0 !px-[12px] !text-[13px] 2xl:w-[496px] xl:w-[280px]">
                          <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-full bg-white">
                            {tp_standard_account_name}
                          </div>
                        </td>
                        {fiscalPeriods.map((periodString) => {
                          return (
                            <td className="w-[112px] border-t-[1px] border-card-on-surface !h-[35px] !text-[13px] !py-0 !px-0">
                              <div className="!pl-[12px] !pr-[26px] !text-[13px] flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[112px]">
                                {formatNumber(amount[periodString])}
                              </div>
                            </td>
                          );
                        })}
                        <td className=" border-t-[1px] border-card-on-surface sticky right-0 !h-[35px] !py-0 !px-0 w-[112px]">
                          <div className="w-[112px] bg-white !px-[12px] flex justify-end items-center">
                            <button
                              onClick={() => {
                                onConfirm(documentType, name, account_code);
                              }}
                              className={`${
                                isConfirmed
                                  ? "confirm text-white border-0"
                                  : "border-[1px]"
                              } !p-0 w-[74px] h-[22px] default  border-border-neutral-medium hover:border-neutral-light font-[600] leading-[16px] text-[12px] tracking-[-0.24px]`}
                            >
                              {isConfirmed ? "Confirmed" : "Confirm"}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
