import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { GlobalWorkerOptions } from "pdfjs-dist";
import PropTypes from "prop-types";
import dragIcon from "../../assets/icons/icon_drag.svg";
import zoomInIcon from "../../assets/icons/icon_pdf_preview_zoom_in.svg";
import zoomOutIcon from "../../assets/icons/icon_pdf_preview_zoom_out.svg";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

PdfPreview.propTypes = {
  fileUrl: PropTypes.string,
  onPageLoad: PropTypes.func,
};

export default function PdfPreview({
  fileUrl,
  onPageLoad = ({ items: _items, styles: _styles }) => {},
}) {
  const zoomPluginInstance = zoomPlugin({
    enableShortcuts: true,
  });

  const selectionModePluginInstance = selectionModePlugin();
  const { SwitchSelectionMode } = selectionModePluginInstance;
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  const handlePageChange = ({ currentPage: currentPageIndex, doc }) =>
    doc.getPage(currentPageIndex + 1).then((page) => {
      return page.getTextContent().then((textContent) => {
        // need to set timeout, otherwise query selector all will return empty for some reason
        setTimeout(() => {
          onPageLoad(textContent, page.view, currentPageIndex);
        }, 100);
      });
    });

  return (
    <div className="relative transition-all duration-500">
      <Worker workerUrl="/pdf.worker.min.js">
        <div className="sticky flex flex-col top-0 right-0 z-10 h-[36px]">
          <CurrentScale>
            {(props) => (
              <div className="text-[#121212] leading-[28px] text-base font-bold self-center">{`${Math.round(
                props.scale * 100
              )}%`}</div>
            )}
          </CurrentScale>
          <div className="flex flex-col gap-[16px] self-end">
            <ZoomIn>
              {(props) => (
                <button onClick={props.onClick}>
                  <img
                    alt="zoom in"
                    className="w-[52px] h-[52px] p-[14px] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                    src={zoomInIcon}
                  />
                </button>
              )}
            </ZoomIn>
            <ZoomOut>
              {(props) => (
                <button onClick={props.onClick}>
                  <img
                    alt="zoom out"
                    className="w-[52px] h-[52px] p-[14px] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                    src={zoomOutIcon}
                  />
                </button>
              )}
            </ZoomOut>
            <SwitchSelectionMode mode={SelectionMode.Hand}>
              {(props) => (
                <button onClick={props.onClick}>
                  <img
                    alt="drag"
                    className="w-[52px] h-[52px] p-[14px] rounded-[50%] border-[1px] border-primary-200 bg-handle-pdf-bg cursor-pointer"
                    src={dragIcon}
                  />
                </button>
              )}
            </SwitchSelectionMode>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Viewer
            fileUrl={fileUrl}
            plugins={[zoomPluginInstance, selectionModePluginInstance]}
            onPageChange={handlePageChange}
          />
        </div>
      </Worker>
    </div>
  );
}
