import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import sendIcon from "../../assets/icons/icon_send.svg";
import { ROLES } from "../../constants";
import { useAuthContext } from "../../context/Auth";
import {
  ADMIN_CARDS,
  greeting,
  MANAGER_CARDS,
  UNDERWRITER_CARDS,
} from "./helpers";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const cards = useMemo(() => {
    switch (user?.role) {
      case ROLES.UNDERWRITER:
        return UNDERWRITER_CARDS({ navigate });
      case ROLES.MANAGER:
        return MANAGER_CARDS({ navigate });
      case ROLES.ADMIN:
        return ADMIN_CARDS({ navigate });
      default:
        return <></>;
    }
  }, [user?.role]);

  return (
    <Fragment>
      <div className="bg-page-gradient fixed inset-0 z-[-1]" />
      <div className="px-16 pb-16 flex justify-between flex-col h-screen-minus-header w-full">
        <div className="pt-[64px]">
          <div className="pb-8">
            <div className="pb-[64px]">
              <div>
                <span className="text-gradient-aurora-blue text-[56px] font-[700] tracking-[-2.88px] leading-[80px]">
                  {greeting()}, {user?.first_name ?? ""}!
                </span>
              </div>
              <h2 className="text-Gray-500 mt-[12px] font-[600] leading-[48px] text-[44px] tracking-[-1.76px]">
                How can I help you today?
              </h2>
            </div>
            <div
              // className="flex flex-row justify-center gap-[16px] w-full "
              className="flex flex-row justify-center flex-wrap gap-[16px] w-full "
              style={{ transform: `scale(${1})` }}
            >
              {cards.map(({ onClick, description, title, icon }, _) => (
                <div
                  key={title}
                  className="hover:bg-primary-2 cursor-pointer w-[340px] min-h-[250px] p-[2px] rounded-[20px]"
                  onClick={onClick}
                >
                  <div
                    className="bg-card-gradient w-full h-full rounded-[20px] flex flex-col justify-between"
                    style={{
                      padding: 24,
                    }}
                  >
                    <div className="pb-8 text-left">
                      <h3
                        className="text-primary-950 font-[600]"
                        style={{
                          fontSize: 18,
                          lineHeight: "24px",
                          letterSpacing: -0.54,
                          marginBottom: 12,
                        }}
                      >
                        {title}
                      </h3>
                      <p
                        className="text-black-Alpha-600 font-[500]"
                        style={{
                          fontSize: 14,
                          lineHeight: "20px",
                          letterSpacing: -0.28,
                        }}
                      >
                        {description}
                      </p>
                    </div>
                    <div className="flex justify-end w-full">
                      <div
                        className="aspect-square bg-white rounded-full flex items-center border-[7px] border-primary-50 justify-center"
                        style={{
                          width: 68,
                        }}
                      >
                        <img
                          src={icon}
                          alt={title}
                          className="aspect-square"
                          style={{
                            width: 36,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="mx-auto w-full max-w-[884px] justify-between flex flex-col gap-[24px]">
          <p className="text-left font-[500] text-[16px] text-neutral-medium">
            For additional actions such as viewing your profile or entering
            direct commands (e.g., ‘I want to add a new set of financial
            statements for Trustplus AI’), please type your instructions
            directly into the text bar below.
          </p>
          <div className="relative w-full">
            <input
              className="bg-white opacity-55"
              placeholder="Type your instructions here"
            />
            <img
              src={sendIcon}
              alt="Send"
              className="w-10 h-10 bottom-1 absolute right-5"
            />
          </div>
        </div> */}
        <div className="min-h-[24px]"></div>
      </div>
    </Fragment>
  );
}
