import { useEffect, useMemo, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import iconTableUpload from "../../assets/icons/icon_upload_document.svg";
import Preview from "../../components/Preview";
import Tabs from "../../components/Tabs";
import Tooltip from "../../components/Tooltip";
import UploadModal from "../../components/UploadModal";
import {
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_UPLOADED,
  TOAST_TYPE,
} from "../../constants";
import { DEVICE_WIDTH } from "../../constants/dimension";
import { FORMULA_GROUPING, FORMULAS_MAP } from "../../constants/formula";
import {
  API_APPLICATIONS,
  API_SAVE_APPLICATION,
  URL_APPLICATION_CREATE,
  URL_APPLICATION_RESULT,
  API_GET_APPLICATION_FILE,
  API_VALIDATE_APPLICATION,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import debounce from "../../utils/api/debounce";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import generatePeriodString from "../../utils/string/generatePeriodString";
import FinancialSpreadingValidateTable from "../FinancialSpreadingUploadValidate/FinancialSpreadingValidateTable";
import axios from "axios";
import { ReactComponent as ResizeIcon } from "../../assets/icons/icon-resize.svg";
import generateHighlightDiv from "../../utils/financialSpreading/generateHighlightDiv";
import { API_APPLICATION_STATUS } from "../../constants/statuses";

const INITIAL_CONFIRM_STATE = DOCUMENT_TYPE_UPLOADED.reduce(
  (finalConfirmState, { key }) => {
    const confirmState = {};
    const documentTypeGroups = FORMULA_GROUPING[key];
    documentTypeGroups.forEach(({ summary = [], name }) => {
      const groupConfirmState = confirmState[name] || {};
      summary.forEach(({ displayCode }) => {
        groupConfirmState[displayCode] = false;
      });

      confirmState[name] = groupConfirmState;
    });

    return {
      ...finalConfirmState,
      [key]: confirmState,
    };
  },
  {}
);

export default function FinancialSpreadingUploadValidate() {
  const { application_id } = useParams();
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const [pendingSave, setPendingSave] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const fileRef = useRef(null);
  const [aggregatedTextContents, setAggregatedTextContents] = useState([]);
  const highlightsRef = useRef([]);
  const [selectedRow, setSelectedRow] = useState();
  const [fileHeight, setFileHeight] = useState();
  const [triggerReRender, setTriggerRender] = useState(0);
  const [confirmState, setConfirmState] = useState(INITIAL_CONFIRM_STATE);

  const handleResetConfirm = (documentType, groupName) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};

      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {},
        },
      };
    });
  };

  const handleConfirmStateUpdate = (documentType, groupName, displayCode) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};
      const groupConfirmState = documentTypeConfirmState[groupName] || {};
      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {
            ...groupConfirmState,
            [displayCode]: true,
          },
        },
      };
    });
  };

  useEffect(() => {
    const selectedRef = highlightsRef.current[selectedRow];
    highlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });

    if (!selectedRef) {
      return;
    }
    selectedRef.style.display = "";
  }, [selectedRow]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_APPLICATIONS}/${application_id}`, {
        withCredentials: true,
      })
      .then(
        ({
          data: {
            data: { data, metadata, status },
          },
        }) => {
          data.sort((a, b) => a.row_number - b.row_number);
          setData(data);
          setMetadata(metadata);

          if (status === API_APPLICATION_STATUS.VALIDATED) {
            // set all confirm state to true if already validated
            setConfirmState(
              DOCUMENT_TYPE_UPLOADED.reduce((finalConfirmState, { key }) => {
                const confirmState = {};
                const documentTypeGroups = FORMULA_GROUPING[key];
                documentTypeGroups.forEach(({ summary = [], name }) => {
                  const groupConfirmState = confirmState[name] || {};
                  summary.forEach(({ displayCode }) => {
                    groupConfirmState[displayCode] = true;
                  });
              
                  confirmState[name] = groupConfirmState;
                });
              
                return {
                  ...finalConfirmState,
                  [key]: confirmState,
                }
              }, {})
            )
          }
        }
      )
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
        navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [application_id]);

  useEffect(() => {
    const getApplicationFileApi = API_GET_APPLICATION_FILE.replace(
      ":id",
      application_id
    );
    axios
      .get(getApplicationFileApi, {
        withCredentials: true,
        responseType: "blob",
      })
      .then((response) => {
        const fileBlob = response.data;
        const fileType = fileBlob.type;
        const createdFileUrl = URL.createObjectURL(fileBlob);
        setFileUrl(createdFileUrl);
        setFileType(fileType);
      })
      .catch((err) => {
        console.error("error fetching application file:", err);
      });
  }, [application_id]);

  const handleSaveData = async () => {
    const saveApplicationApi = API_SAVE_APPLICATION.replace(
      ":id",
      application_id
    );
    axios
      .put(
        saveApplicationApi,
        { data, new_metadata: metadata },
        {
          withCredentials: true,
        }
      )
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
      });
  };

  const debouncedSave = debounce(handleSaveData, 500);

  useEffect(() => {
    if (pendingSave) {
      debouncedSave();
      setPendingSave(false);
    }
  }, [pendingSave]);

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [isScroll, setIsScroll] = useState(false);

  const { width } = useWindowDimensions();

  const handleScroll = () => {
    if (window?.scrollY < 100) {
      setIsScroll(false);
    } else {
      setIsScroll(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll, 500));

    return () => {
      window.removeEventListener("scroll", debounce(handleScroll, 500));
    };
  }, []);

  const loadDataIntoPdf = (
    textContent,
    [, , width, height],
    currentPageIndex
  ) => {
    setFileHeight(height);
    setIsFileLoaded(true);
    const { items } = textContent;
    const result = [];
    let currentArray = [];
    let constructedStr = "";

    const currentPageDiv = fileRef.current.querySelectorAll(
      ".rpv-core__inner-page"
    )[currentPageIndex];

    const textDivs = currentPageDiv.querySelectorAll(
      ".rpv-core__text-layer-text"
    );

    textDivs.forEach((element, index) => {
      const str = element.textContent;
      const isNewLine = element.tagName === "BR";
      if (isNewLine) {
        if (currentArray.length > 0) {
          result.push({
            constructedStr,
            content: currentArray,
            width,
            height,
          });
          currentArray = [];
          constructedStr = "";
        }
      } else {
        currentArray.push({ parentRef: textDivs[index] });
        constructedStr += str;
      }
    });

    setAggregatedTextContents(result);
  };

  useEffect(() => {
    if (!data.length || !aggregatedTextContents.length) {
      return;
    }

    highlightsRef.current.forEach((ref) => ref.remove());
    highlightsRef.current = [];
    setSelectedRow(null);
    let lastMatchedIndex = 0;

    data.forEach(({ client_account_name, document_type }, dataIndex) => {
      if (document_type !== currentDocumentType) {
        return;
      }
      for (let i = lastMatchedIndex; i < aggregatedTextContents.length; i++) {
        const { constructedStr, content } = aggregatedTextContents[i];

        const lowerClientAccountName = client_account_name.toLowerCase().trim();
        const lowerConstructedStr = constructedStr.toLowerCase().trim();

        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];

          const { left, height } = firstParentElement.getBoundingClientRect();

          const { right } = lastParentElement.getBoundingClientRect();

          const parentTransform =
            window.getComputedStyle(firstParentElement).transform;

          let scaleX = 1;

          if (parentTransform !== "none") {
            const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

            if (matrixValues) {
              const values = matrixValues[1].split(",");

              scaleX = parseFloat(values[0]);
            }
          }

          const highlightDiv = generateHighlightDiv({
            width: (right - left) / scaleX + 4,
            height,
          });

          highlightsRef.current[dataIndex] = highlightDiv;
          firstParentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          break;
        }

        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
          const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];
          const firstParentElements = [firstParentElement];
          const lastParentElements = [lastParentElement];
          let remainingCheckedStr = lowerClientAccountName
            .substring(lowerConstructedStr.length)
            .trim();
          for (let j = i + 1; j < aggregatedTextContents.length; j++) {
            const { constructedStr, content } = aggregatedTextContents[j];
            const lowerConstructedStr = constructedStr.toLowerCase().trim();

            if (
              !(
                lowerConstructedStr.startsWith(remainingCheckedStr) ||
                remainingCheckedStr.startsWith(lowerConstructedStr)
              )
            ) {
              i = j - 1;
              break;
            }

            const { parentRef: firstParentElement } = content[0];
            const { parentRef: lastParentElement } =
              content[content.length - 1];
            firstParentElements.push(firstParentElement);
            lastParentElements.push(lastParentElement);

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j;
              break;
            }
          }
          const { left, top } = firstParentElement.getBoundingClientRect();

          const { bottom: bottomFirstParentElementBottom } =
            firstParentElements[
              firstParentElements.length - 1
            ].getBoundingClientRect();
          const height = bottomFirstParentElementBottom - top;

          const right = lastParentElements.reduce(
            (right, currentLastParentElement) => {
              const { right: currentRight } =
                currentLastParentElement.getBoundingClientRect();
              if (currentRight > right) {
                return currentRight;
              }

              return right;
            },
            0
          );

          const parentTransform =
            window.getComputedStyle(firstParentElement).transform;

          let scaleX = 1;

          if (parentTransform !== "none") {
            const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

            if (matrixValues) {
              const values = matrixValues[1].split(",");

              scaleX = parseFloat(values[0]);
            }
          }

          const highlightDiv = generateHighlightDiv({
            width: (right - left) / scaleX + 4,
            height,
          });

          highlightsRef.current[dataIndex] = highlightDiv;
          firstParentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;

          break;
        }
      }
    });
  }, [aggregatedTextContents, data]);

  const handleValidateApplication = () => {
      const validateApplicationApi = API_VALIDATE_APPLICATION.replace(
        ":id",
        application_id
      );
  
      axios
        .put(
          validateApplicationApi,
          null,
          {
            withCredentials: true,
          }
        )
        .then(() => {
          navigate(`${URL_APPLICATION_RESULT}/${application_id}`)
        })
        .catch((err) => {
          showToast(err.message, TOAST_TYPE.ERROR);
        });
  }

  const renderButton = useMemo(() => {
    const isNextButtonDisabled = DOCUMENT_TYPE_UPLOADED.some(({ key }) => {
      const documentTypeGroups = FORMULA_GROUPING[key];
      const documentTypeConfirmState = confirmState[key] || {};

      return documentTypeGroups.some(({ summary = [], name }) => {
        const groupConfirmState = documentTypeConfirmState[name] || {};
        return summary.some(({ displayCode }) => {
          return !groupConfirmState[displayCode];
        });
      });
    });

    return (
      <div className="flex gap-[20px]">
        <button
          onClick={() => navigate(URL_APPLICATION_CREATE)}
          className="w-[100px] !h-[48px] !py-[12px] !px-[20px] default border-[1px] border-neutral-medium font-[600] leading-[24px] tracking-[-0.48px] text-[16px]"
        >
          Previous
        </button>
        <button
          onClick={() =>
            handleValidateApplication()
          }
          disabled={isNextButtonDisabled}
          className="w-[100px] !h-[48px] !py-[12px] !px-[20px] primary font-[600] leading-[24px] tracking-[-0.48px] text-[16px] text-white p-[12px]"
        >
          Next
        </button>
      </div>
    );
  }, [confirmState, application_id, navigate]);

  const currentDocumentType = activeTab;
  const documentMetadata = metadata[currentDocumentType] || {};
  const { page_number, fiscal_period_order = [] } = documentMetadata;

  const documentTypeGroups = FORMULA_GROUPING[currentDocumentType];

  const scrollToPage = () => {
    const initialPageDiv = fileRef.current.querySelectorAll(
      ".rpv-core__inner-page"
    )[page_number - 1];

    if (initialPageDiv) {
      const fileRect = fileRef.current.getBoundingClientRect();
      const elementRect = initialPageDiv.getBoundingClientRect();

      const scrollOffset =
        elementRect.top - fileRect.top + fileRef.current.scrollTop;

      const parent = fileRef.current.parentElement;
      const parentRect = parent.getBoundingClientRect();

      window.scrollTo({
        top: window.scrollY + parentRect.top, // Scroll window to container's top position
        behavior: "smooth",
      });

      fileRef.current.scrollTo({
        top: scrollOffset,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  useEffect(() => {
    if (triggerReRender) {
      setTimeout(() => {
        if (page_number && isFileLoaded) {
          scrollToPage();
        }
      }, 200);
    }
  }, [triggerReRender]);

  useEffect(() => {
    if (page_number && isFileLoaded) {
      scrollToPage();
    }
  }, [page_number, isFileLoaded]);

  const addNewDataRow = (index) => {
    const newData = [...data];
    const emptyRow = {
      client_account_name: "",
      tp_standard_account_name: "",
      account_code: "",
      document_type: currentDocumentType,
      amount: fiscal_period_order.reduce(
        (acc, period) => ({ ...acc, [period]: 0 }),
        {}
      ),
    };
    newData.splice(index + 1, 0, emptyRow);
    setData(newData);
  };

  const deleteRow = (index) => {
    setData((currentData) => currentData.filter((_, i) => i !== index));
    setPendingSave(true);
  };

  const handleSaveRow = (index, newRowData) => {
    setData((currentData) => {
      const newData = [...currentData];

      newData[index] = newRowData;

      return newData;
    });

    setPendingSave(true);
  };

  const onInverseRow = (index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { amount = {} } = newData[index];

      const newAmount = { ...amount };

      for (let periodString in newAmount) {
        const value = newAmount[periodString] || 0;

        newAmount[periodString] = value * -1;
      }

      newData[index] = {
        ...newData[index],
        amount: newAmount,
      };

      return newData;
    });

    setPendingSave(true);
  };

  const handleSave = () => {
    setPendingSave(true);
  };

  const { getStandardAccountOptions } = useApplicationContext();

  const standardAccountOptions = (
    getStandardAccountOptions(currentDocumentType) || []
  )?.map(({ account_code, tp_standard_account_name }) => ({
    value: account_code,
    label: tp_standard_account_name,
  }));

  const handleAmountKeyChange = (
    documentType,
    previousFiscalPeriod,
    newFiscalPeriod
  ) => {
    if (previousFiscalPeriod === newFiscalPeriod) {
      return;
    }

    let newFiscalPeriodExists = false;

    setData((currentData) => {
      const newData = [];
      for (let i = 0; i < currentData.length; i++) {
        const datum = currentData[i] || {};
        const { amount, document_type } = datum;

        if (document_type !== documentType) {
          newData.push(datum);
          continue;
        }

        if (newFiscalPeriod in amount) {
          newFiscalPeriodExists = true;
          break;
        }

        const newAmount = { ...amount };

        if (previousFiscalPeriod in newAmount) {
          newAmount[newFiscalPeriod] = newAmount[previousFiscalPeriod];
          delete newAmount[previousFiscalPeriod]; // Remove the old key
        }

        datum.amount = newAmount;
        newData.push(datum);
      }

      if (newFiscalPeriodExists) {
        return currentData;
      }

      return newData;
    });

    if (newFiscalPeriodExists) {
      showToast("Period already exists", TOAST_TYPE.ERROR);
      return;
    }

    setMetadata((currentMetadata) => {
      const { [documentType]: currentDocumentTypeMetadata = {} } =
        currentMetadata;
      const { fiscal_period_order = [] } = currentDocumentTypeMetadata;
      const newFiscalPeriodOrder = [...fiscal_period_order];

      for (let i = 0; i < fiscal_period_order.length; i++) {
        const currentFiscalPeriod = fiscal_period_order[i];
        if (currentFiscalPeriod === previousFiscalPeriod) {
          newFiscalPeriodOrder[i] = newFiscalPeriod;
          break;
        }
      }

      return {
        ...currentMetadata,
        [documentType]: {
          ...currentDocumentTypeMetadata,
          fiscal_period_order: newFiscalPeriodOrder,
        },
      };
    });

    setPendingSave(true);
  };

  const onDateChange = (documentType, previousFiscalPeriod, month, year) => {
    const { period, audited } = extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(month, year, period, audited);

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onPeriodChange = (documentType, previousFiscalPeriod, period) => {
    const { monthInt, year, audited } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onAuditedChange = (documentType, previousFiscalPeriod, audited) => {
    const { monthInt, year, period } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const calculatedUploadedDocuments = useMemo(() => {
    const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(
      ({ key }) => metadata[key]
    );
    const calculatedFormulas = calculatedDocumentTypesArr.reduce(
      (fin, { key, formula_type = key }) => {
        const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
          ...data,
          document_type: formula_type,
        }));
        return [...fin, ...currFormula];
      },
      []
    );

    const baseCalculateMetadata = calculatedDocumentTypesArr.reduce(
      (existingMetadata, { key, formula_type = key }) => {
        return {
          ...existingMetadata,
          [formula_type]: metadata[key],
        };
      },
      {
        isOldestYear: false,

        // TODO: replace this value's usage to use extract from period string instead
        numberOfMonths: 12,
      }
    );

    const result = calculateFormulaFromData(
      data,
      calculatedFormulas,
      baseCalculateMetadata
    );

    result.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
    );
    return result;
  }, [data, metadata]);

  const onBack = () => navigate(URL_APPLICATION_CREATE);

  // width for client account name / tp standard account name
  const namesWidth = width >= DEVICE_WIDTH.LAPTOP ? 248 : 140;
  const nonNamesWidth = 112;
  const minWidth =
    namesWidth * 2 + (fiscal_period_order.length + 1) * nonNamesWidth;

  return (
    <div
      className={`w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[40px] flex-col gap-[16px]">
        <div className="pb-[16px] flex justify-between">
          <div className="flex gap-[16px] flex-1 items-center">
            <button onClick={onBack}>
              <img
                src={backIcon}
                alt="back-button"
                className="cursor-pointer"
              />
            </button>
            <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Financial Spreading Confirmation
            </span>
          </div>
          {!isScroll && renderButton}
        </div>
        <ul className="list-disc pl-[26px] xl:text-[18px] ssm:text-[14px] leading-[28px] tracking-[-0.54px] font-[500] max-w-[1187px] w-full">
          <li>
            Review Documents: Compare the original financial statements on the
            left with the mapped accounts and figures on the right.
          </li>
          <li>
            Verify and Edit: Confirm accuracy of mappings and figures. For
            corrections, click the column, select the appropriate account or
            adjust numbers as needed. After verifying all details, click “Next”
            to proceed
          </li>
        </ul>
      </div>

      <div className="flex flex-col items-center gap-[32px] w-full">
        <div className="max-w-[1792px] w-full flex flex-col gap-[24px]">
          <div
            className={`flex justify-between align-center ${
              isScroll ? "sticky bg-[#fff] top-0 z-[20] py-[12px] px-[8px]" : ""
            }`}
          >
            <Tabs
              tabsLabel={DOCUMENT_TYPE_UPLOADED}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              wrapperClassname={
                isScroll ? "py-[12px] px-[8px] !border-none" : ""
              }
            />
            {isScroll && renderButton}
          </div>
          <PanelGroup direction="horizontal" className="gap-[2px]">
            <Panel
              defaultSizePercentage={33}
              maxSize={50}
              className="flex flex-col gap-[24px] min-w-[430px] sticky top-[134px]"
            >
              <div className="flex gap-[24px] items-center">
                <span className="text-[18px] font-[700] leading-[32px] tracking-[-0.72px]">
                  Original Document
                </span>
                <div onClick={() => setOpenUploadModal((prev) => !prev)}>
                  <Tooltip
                    tooltip="Re-upload Document"
                    tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[127px]"
                  >
                    <img
                      src={iconTableUpload}
                      className="p-[8px] cursor-pointer"
                      title="Re-upload Document"
                    />
                  </Tooltip>
                </div>
              </div>
              <div
                key={triggerReRender}
                className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col w-full aspect-[4/3] overflow-y-scroll"
                ref={fileRef}
                style={{
                  height: fileHeight - 50 || undefined,
                }}
              >
                <Preview
                  url={fileUrl}
                  type={fileType}
                  onPageLoad={loadDataIntoPdf}
                />
              </div>
            </Panel>
            <PanelResizeHandle
              className="relative w-[24px] h-[24px] top-[300px] flex items-center justify-center"
              onDragging={(isDragging) => {
                if (!isDragging) {
                  setTriggerRender((prev) => prev + 1);
                }
              }}
            >
              <ResizeIcon color="black" />
            </PanelResizeHandle>
            <Panel
              defaultSizePercentage={60}
              minSize={50}
              className="flex flex-col gap-[24px] overflow-x-auto"
              style={{
                width: minWidth,
              }}
            >
              <span className="text-[18px] font-[700] leading-[32px] tracking-[-0.72px]">
                Financial Mapping
              </span>
              <div
                className={`flex-col gap-[24px] flex`}
                style={{
                  height: fileHeight - 50 || undefined,
                  overflow: "auto",
                }}
              >
                {documentTypeGroups.map(
                  ({ name, summary, content, canInverseValue }, index) => {
                    const groupConfirmState =
                      confirmState[currentDocumentType][name];

                    return (
                      <FinancialSpreadingValidateTable
                        // minWidth={minWidth}
                        name={name}
                        fiscalPeriods={fiscal_period_order}
                        data={data}
                        documentType={currentDocumentType}
                        standardAccountOptions={standardAccountOptions}
                        onAddRow={addNewDataRow}
                        onDeleteRow={deleteRow}
                        onSaveRow={handleSaveRow}
                        canChangeDate={index === 0}
                        onDateChange={onDateChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onPeriodChange={onPeriodChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onAuditedChange={onAuditedChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onSave={handleSave}
                        summary={summary}
                        content={content}
                        calculatedData={calculatedUploadedDocuments}
                        onInvertClick={canInverseValue && onInverseRow}
                        onRowHover={setSelectedRow}
                        confirmState={groupConfirmState}
                        onConfirm={handleConfirmStateUpdate}
                        onResetConfirm={handleResetConfirm}
                      />
                    );
                  }
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </div>
      <div className="min-h-[24px]"></div>
      {openUploadModal && (
        <UploadModal
          isOpen={openUploadModal}
          closeModal={() => setOpenUploadModal(false)}
        />
      )}
    </div>
  );
}
