import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/icon_info.svg";

export default function Dropdowns({
  data = [],
  label,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
  labelClassName = "",
  onChange,
  allowCustomText = false,
  disabled = false,
  required = false,
  ...props
}) {
  const { id, name = id } = props;

  const [dropDownData, setDropDownData] = useState(data);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [focusedOnly, setFocusedOnly] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChangeFilter = (keyWords) => {
    setDropDownData(
      data.filter((item) =>
        item.label.toLowerCase().includes(keyWords.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  let shownDefaultValue = defaultValue;

  if (!allowCustomText && typeof defaultValue !== "undefined") {
    const defaultData = dropDownData.find(
      ({ value }) => value === defaultValue
    );
    shownDefaultValue = defaultData?.label;
  }

  return (
    <div
      className={`relative inline-block text-left ${className}`}
      ref={dropdownRef}
    >
      <label htmlFor="menu-button" className={labelClassName}>
        {label}
      </label>
      <div
        className="relative flex cursor-pointer"
        onClick={() => setOpen((prev) => !prev)}
      >
        <input
          name={name}
          ref={inputRef}
          id="menu-button"
          defaultValue={shownDefaultValue}
          autoComplete="off"
          aria-expanded={open}
          aria-haspopup="true"
          placeholder={placeholder}
          onChange={(e) => {
            if (allowCustomText) {
              onChange?.(e.target.value, name);
            }
            handleChangeFilter(e.target.value);
          }}
          disabled={disabled}
          onFocus={() => setFocusedOnly(true)}
          required={required}
          className={`text-[#121212] font-[600] text-[14px] leading-[24px] tracking-[-0.32px] placeholder:font-[400] ${inputClassName} ${
            required && focusedOnly && !props?.value
              ? "!border-b-[#F65737] !border-b-[1.5px]"
              : ""
          }`}
        />
        <span
          className={`absolute right-[12px] top-[50%] translate-y-[-50%] ${
            open ? "rotate-180" : ""
          }`}
        >
          <ArrowDropdownIcon color="#121212" />
        </span>
      </div>
      {focusedOnly && required && !props?.value && (
        <div className="flex items-center gap-[4px] flex-grow flex-shrink-0 basis-0 mt-[4px] absolute">
          <InfoIcon color="#F65737" />
          <span className="text-[#F65737] text-[12px] leading-[16px]">
            Please fill up this field
          </span>
        </div>
      )}
      {open && (
        <div
          className="absolute w-full z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-[8px] max-h-[200px] overflow-y-auto" role="none">
            {dropDownData.length === 0 && (
              <span
                className="block px-[12px] py-[8px] text-[14px] font-[500] leading-[20px] tracking-[-0.28px] "
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                Not found
              </span>
            )}
            {dropDownData.length > 0 &&
              dropDownData.map((item) => (
                <span
                  className="block px-[12px] py-[8px] text-[14px] font-[500] leading-[20px] tracking-[-0.28px] hover:bg-neutral-flat-hover"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  key={item.value}
                  onClick={() => {
                    onChange?.(item.value, name);
                    inputRef.current.value = item.label;
                    setOpen(false);
                  }}
                >
                  {item.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
