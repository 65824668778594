import axios from "axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_PENDING_APPLICATIONS } from "../../constants/url";
import { useToaster } from "../Toaster";
import { APPLICATION_STATUS_PENDING_APPLICATIONS_MAP, PENDING_APPLICATION_STATUS } from "../../constants/statuses";
import { TOAST_TYPE } from "../../constants";
import debounce from "../../utils/api/debounce";

const PendingApplicationsContext = createContext({
  pendingApplications: [],
  hasPendingApplication: false,
  refreshPendingApplication: () => {},
});

export const usePendingApplications = () => useContext(PendingApplicationsContext)

export default function PendingApplicationsProvider({ children }) {
  const [pendingApplications, setPendingApplications] = useState([]);
  const { showToast } = useToaster();
  const intervalId = useRef(null)
  const isFetching = useRef(false)

  const checkHasPendingApplication = (applications) => applications.some(
    (application) =>
      application.status === PENDING_APPLICATION_STATUS.IN_PROGRESS
  );


  const getPendingApplications = async () => {
    if (isFetching.current) {
      return
    }

    isFetching.current = true
    return axios
    .get(API_PENDING_APPLICATIONS, {
      withCredentials: true,
    })
    .then(({ data }) => {
      const newPendingApplication = data
      .map(({ status, id, company_name }) => ({
        companyName: company_name,
        id,
        status: APPLICATION_STATUS_PENDING_APPLICATIONS_MAP[status],
      }))
      .filter(Boolean)

      setPendingApplications(newPendingApplication);
 
      return newPendingApplication
    })
    .catch(() => {
      showToast("failed to get pending applications", TOAST_TYPE.ERROR);
    }).finally(() => {
      isFetching.current = false
    });
  }

  const debouncedGetPendingApplication = debounce(
    getPendingApplications,
    500
  );


  const cancelPoll = () => {
    clearInterval(intervalId.current);
    intervalId.current = null;
    debouncedGetPendingApplication.cancel()
  }

  const startPoll = () => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => getPendingApplications().then((newPendingApplications) => {
        if (newPendingApplications && !checkHasPendingApplication(newPendingApplications)) {
          cancelPoll()
        }
      }), 5000);
    }
  }

  const refreshPendingApplication = () => {
    debouncedGetPendingApplication()
    startPoll()
  }


  useEffect(() => {
    refreshPendingApplication()

    return () => {
      cancelPoll()
    }
  }, [])

  const hasPendingApplication = checkHasPendingApplication(pendingApplications)

    return (
        <PendingApplicationsContext.Provider value={{ pendingApplications, hasPendingApplication, refreshPendingApplication }}>{children}</PendingApplicationsContext.Provider>
    )

}