import PropTypes from "prop-types";
import React, { useState } from "react";
import errorIcon from "../../assets/images/error.svg";

TextInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  referralOptions: PropTypes.arrayOf(PropTypes.string),
};

export default function TextInput({
  label,
  className,
  errorMessage,
  referralOptions = [],
  ...props
}) {
  const { id, name = id } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className={`${className} relative`}>
      {label && (
        <label
          htmlFor={id}
          className="pb-[4px] text-[#121212] text-[14px] font-medium"
        >
          {label}
        </label>
      )}
      <input
        {...props}
        name={name}
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="text-[#121212] text-[14px] font-medium font-[600] text-[16px] leading-[24px] tracking-[-0.32px] placeholder:font-[400]"
        style={{
          borderBottom: errorMessage
            ? "1.5px solid var(--color-border-error-default)"
            : "none",
        }}
      />
      {(errorMessage || (props?.required && isFocused && !props?.value)) && (
        <div className="flex gap-[4px] pt-[4px] absolute">
          <img src={errorIcon} alt="Error" />
          <span
            style={{
              color: "var(--color-border-error-default)",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16px",
            }}
          >
            {errorMessage ?? "Please fill up this field"}
          </span>
        </div>
      )}

      {isFocused && referralOptions.length > 0 && (
        <div
          className="absolute w-full z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-[8px] max-h-[200px] overflow-y-auto" role="none">
            <span className="px-[12px] py-[8px] font-[600] text-[12px] leading-[16px] text-neutral-light">
              You can use this list as referral
            </span>
            {referralOptions.map((item) => (
              <span
                className="block px-[12px] py-[8px] text-[14px] font-[500] leading-[20px] tracking-[-0.28px] hover:bg-neutral-flat-hover"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
                key={item}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
