import { useRef, useState } from "react";

import arrow from "../../assets/icons/keyboard_arrow_up.png";

export default function Collapse({ panel, title }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div className="bg-white p-[24px] rounded-[20px] border-[1px] border-card-on-surface">
      <div
        className="w-full flex justify-between select-none cursor-pointer"
        data-collapse-target="collapse"
        onClick={toggleCollapse}
      >
        <span className="text-[18px] font-[700] leading-[24px] tracking-[-0.54px]">
          {title}
        </span>
        <img
          alt=""
          src={arrow}
          className={`w-[24px] h-[24px] ${isOpen ? "" : "rotate-180"}`}
        />
      </div>
      {isOpen && <div
        data-collapse="collapse"
        className={`w-full basis-full overflow-hidden transition-all duration-300 ease-in-out`}
      >
        {panel}
      </div>}
    </div>
  );
}
