export const API_APPLICATION_STATUS = {
  CREATED: "CREATED",
  VALIDATING: "VALIDATING",
  VALIDATED: "VALIDATED",
  CONFIRMED: "CONFIRMED",
  INACTIVE: "INACTIVE",
};

export const PENDING_APPLICATION_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  FAILED: "FAILED",
};

export const APPLICATION_STATUS_PENDING_APPLICATIONS_MAP = {
  [API_APPLICATION_STATUS.CREATED]: PENDING_APPLICATION_STATUS.IN_PROGRESS,
  [API_APPLICATION_STATUS.VALIDATING]: PENDING_APPLICATION_STATUS.DONE,
  [API_APPLICATION_STATUS.VALIDATED]: PENDING_APPLICATION_STATUS.DONE,
};
