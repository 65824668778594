import React, { useState } from 'react';
import Providers from './context';
import AppRoutes from './Routes';
import ErrorBoundary from './pages/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <Providers>
        <AppRoutes />
      </Providers>
    </ErrorBoundary>
  );
}

export default App;