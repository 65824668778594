import "chart.js/auto"; // Importing the chart.js defaults
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { CHART_DURATION } from "../../constants";
import TimeControlChart, { timeOptions } from "../TimeControlChart";

export default function BarChart({
  title,
  datasets,
  labels,
  displayLegend = true,
  legendPosition = "top",
  customLegend = <></>,
}) {
  const [selectedTime, setSelectedTime] = useState({
    duration: CHART_DURATION.MONTH,
    period: timeOptions?.[0],
  });
  const combinedData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to adapt to container size
    plugins: {
      legend: {
        display: displayLegend,
        position: legendPosition,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value, // Format the y-axis labels for cost charts
        },
      },
    },
  };

  const handleSelectDuration = (duration) => {
    setSelectedTime((prev) => ({
      ...prev,
      duration,
    }));
  };

  return (
    <div className="bg-[#fff] flex flex-col p-[24px] gap-[24px] items-stretch rounded-[20px]">
      <TimeControlChart
        title={title}
        handleSelectDuration={handleSelectDuration}
        selectedTime={selectedTime}
      />
      <div className="w-full h-[266px]">
        <Bar data={combinedData} options={options} />
      </div>
      {customLegend}
    </div>
  );
}
