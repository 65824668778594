const activeClass =
  "border-b-[2px] text-primary-2 font-[700] leading-[24px] tracking-[-0.32px] underline decoration-primary-2 decoration-[2px] underline-offset-[19px]";
const defaultTabClass = "cursor-pointer text-[16px] pb-[12px]";
const disabledClass = "text-Text-disabled cursor-not-allowed";

export default function Tabs({
  activeTab,
  setActiveTab,
  tabsLabel = [],
  wrapperClassname
}) {
  const handleChangeActiveTabs = (tabKey) => {
    setActiveTab(tabKey);
  };
  return (
    <div class={`w-full flex gap-[24px] border-b-[1px] border-solid border-card-on-surface ${wrapperClassname}`}>
      {tabsLabel.map((label, index) => (
        <span
          className={`${activeTab === label.key ? activeClass : ""
            } ${defaultTabClass}`}
          onClick={() => handleChangeActiveTabs(label.key)}
          key={index}
        >
          {label.text}
        </span>
      ))}
    </div>
  );
}
