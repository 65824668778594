import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import closeIcon from "../../assets/icons/icon_close.svg";

ToastMessage.propTypes = {
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string,
  description: PropTypes.element,
  duration: PropTypes.number,
  bgContainer: PropTypes.string,
  isOpen: PropTypes.bool,
};

function ToastMessage({
  title,
  iconUrl,
  description,
  duration = 3000,
  bgContainer,
  isOpen,
}) {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [duration]);

  const handleClose = () => {
    setIsVisible(false);
  };

  // Return null if toast is not visible
  if (!isVisible) return null;

  return (
    <div
      id="toast-message"
      className="flex w-[430px] justify-between items-start gap-[16px] p-[16px] rounded-[12px] fixed top-5 right-5"
      style={{
        background: bgContainer,
      }}
      role="alert"
    >
      <div className="flex gap-[16px]">
        <img
          src={iconUrl}
          alt={title}
          width={64}
          height={46}
          className="inline-block w-[64px] h-[46px]"
        />
        <div className="flex flex-col justify-center align-start">
          <div className="text-[#121212] text-base font-medium leading-[24px] tracking-[-0.32px]">
            {title}
          </div>
          {description}
        </div>
      </div>
      <button type="button" onClick={handleClose} aria-label="Close">
        <span className="sr-only">Close</span>
        <img src={closeIcon} alt="Close" className="min-w-10 w-10" />
      </button>
    </div>
  );
}

export default ToastMessage;
