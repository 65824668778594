import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import iconSearch from "../../assets/icons/icon_search.svg";
import deleteConfirmImg from "../../assets/images/delete_confirm.png";
import CommonTable from "../../components/CommonTable/cop";
import Modal from "../../components/Modal";
import { SORT_ORDER } from "../../constants";
import { DEVICE_WIDTH } from "../../constants/dimension";
import {
  API_GET_COMPANIES,
  URL_APPLICATION_CREATE,
  URL_COMPANIES,
  URL_DASHBOARD,
} from "../../constants/url";
import { useLoader } from "../../context/Loader";
import { useDebounce } from "../../hooks/useDebounce";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { companyCols, MANAGE_ACCOUNT_COLS } from "./columns";

export default function ManageAccounts() {
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const { width } = useWindowDimensions();

  const [searchValue, setSearchValue] = useState("");
  const [companies, setCompanies] = useState(null);
  const debouncedValue = useDebounce(searchValue, 500);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCompanyID, setselectedCompanyID] = useState(null);

  const [isAscCols, setIsAscCols] = useState({
    [MANAGE_ACCOUNT_COLS.GROUP_NAME]: true,
    [MANAGE_ACCOUNT_COLS.COMPANY_NAME]: true,
    [MANAGE_ACCOUNT_COLS.LASTEST_FISCAL_PERIOD]: true,
    [MANAGE_ACCOUNT_COLS.UPDATED_AT]: true,
  });

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        companies,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setCompanies(
      sortByKey(
        companies,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${API_GET_COMPANIES}/search?name=${debouncedValue}`,
        {
          withCredentials: true,
        }
      );
      if (res.data.code === 200) {
        setLoading(false);
        setCompanies(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [debouncedValue]);

  const handleManageClient = (companyId, mode) => {
    setselectedCompanyID(companyId);
    navigate(`${URL_COMPANIES}/${companyId}?mode=${mode}`);
  };

  const handleUploadNewFinancials = (companyId) => {
    navigate(`${URL_APPLICATION_CREATE}/${companyId}`);
  };

  const handleDeleteCompany = async () => {
    try {
      await axios.delete(`${API_GET_COMPANIES}/${selectedCompanyID}`, {
        withCredentials: true,
      });
      setShowDeleteModal(false);
      fetchCompanies();
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Company List
        </span>
      </div>
      <form class="w-full max-w-[1186px] mx-auto">
        <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <img src={iconSearch} />
          </div>
          <input
            type="search"
            id="default-search"
            class="pl-[48px] text-[16px] font-[400] leading-[24px] tracking-[-0.32px]"
            placeholder="Search..."
            required
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </form>
      <div className="w-full max-w-[1186px] mt-[24px] mx-auto">
        {companies && (
          <CommonTable
            minWidth={
              width >= DEVICE_WIDTH.LAPTOP
                ? companyCols.minWidth
                : companyCols.laptopMinWidth
            }
            columns={companyCols.columns({
              handleManageClient,
              setselectedCompanyID,
              toggleDeleteModal,
              handleSort,
              isAscCols,
              handleUploadNewFinancials,
            })}
            rowsData={companies}
            label="Recent Updates"
            titleClassname="!text-[20px]"
            width={companyCols.width}
            isOverflow={width < DEVICE_WIDTH.LAPTOP ? true : false}
          />
        )}
      </div>
      {showDeleteModal && (
        <Modal
          title={"Delete Confirmation!"}
          img={deleteConfirmImg}
          confirmText="Delete"
          onConfirm={handleDeleteCompany}
          onClose={toggleDeleteModal}
          additionalContent={
            <div className="text-[#525252] text-[18px] leading-[28px] text-center">
              Deleting this user will remove it from your list. This action
              cannot be undone.
            </div>
          }
        />
      )}
    </div>
  );
}
