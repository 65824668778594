import { Link, useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import auroraLogo from "../../assets/images/aurora_logo.svg";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import Dropdowns from "../../components/Dropdowns";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import { useState } from "react";
import { API_CONTACT_US } from "../../constants/url";
import { useToaster } from "../../context/Toaster";
import { TOAST_TYPE } from "../../constants";
import axios from "axios";
import { useLoader } from "../../context/Loader";

export const CONTACT_US_FIELDS = {
  USER_NAME: "user_name",
  USER_EMAIL: "user_email",
  SUBJECT_CATEGORY: "subject_category",
  SUBJECT: "subject",
  MESSAGE: "message"
}

export default function ContactUs({ fromErrorPage }) {
  const navigate = useNavigate()
  const { setLoading } = useLoader()
  const { showToast } = useToaster()

  const initialValues = {
    [CONTACT_US_FIELDS.USER_NAME]: '',
    [CONTACT_US_FIELDS.USER_EMAIL]: '',
    [CONTACT_US_FIELDS.SUBJECT_CATEGORY]: '',
    [CONTACT_US_FIELDS.SUBJECT]: '',
    [CONTACT_US_FIELDS.MESSAGE]: '',
  }

  const [inputValues, setInputValues] = useState(initialValues);

  const handleChange = (event) => {
    setInputValues(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  const handleContactUs = async () => {
    setLoading(true)
    const targetAPI = fromErrorPage ? API_CONTACT_US : `${API_CONTACT_US}/user`
    return await axios.post(targetAPI, inputValues, {
      withCredentials: true,
    }).then(response => {
      showToast(response?.data?.message, TOAST_TYPE.SUCCESS);
    }).catch((err) => {
      showToast(err.message, TOAST_TYPE.ERROR);
    }).finally(() => {
      setInputValues(initialValues)
      setLoading(false)
    })
  }

  return (
    <div className="w-full max-w-default px-16 pb-16 relative min-h-[100vh] flex flex-col">
      {fromErrorPage ? (
        <img
          src={trustplusLogo}
          alt=""
          className="w-[215px] h-[32px] mt-[52px]"
        />
      ) : (
        <div className="py-[40px] flex gap-[16px]">
          <img src={backIcon} alt="back-button" className="cursor-pointer" onClick={() => navigate(-1)} />
          <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
            Contact Us
          </span>
        </div>
      )}
      <div className="flex flex-col justify-center items-center gap-[52px] w-full">
        {fromErrorPage && (
          <img
            src={auroraLogo}
            alt=""
            className="w-[300x] h-[83px] pb-24px mt-[48px]"
          />
        )}
        <div className="w-full max-w-[582px] p-[32px] rounded-[20px] bg-white flex flex-col gap-[24px]">
          {fromErrorPage && (
            <div>
              <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
                Contact Us
              </span>
            </div>
          )}
          <TextInput label="User Name" placeholder="Enter user name" name={CONTACT_US_FIELDS.USER_NAME} onChange={handleChange} value={inputValues[CONTACT_US_FIELDS.USER_NAME]} />
          <TextInput label="User Email" type="email" placeholder="Enter user email" name={CONTACT_US_FIELDS.USER_EMAIL} onChange={handleChange} value={inputValues[CONTACT_US_FIELDS.USER_EMAIL]} />
          <Dropdowns
            label="Subject Category"
            data={[
              {
                label: "Technical Support",
                value: "Technical Support",
              },
              {
                label: "Feedback and Suggestions",
                value: "Feedback and Suggestions",
              },
            ]}
            placeholder="Select from categories"
            name={CONTACT_US_FIELDS.SUBJECT_CATEGORY}
            onChange={(event) => setInputValues(prev => ({
              ...prev,
              [CONTACT_US_FIELDS.SUBJECT_CATEGORY]: event
            }))}
            allowCustomText
            value={inputValues[CONTACT_US_FIELDS.SUBJECT_CATEGORY]}
          />
          <TextInput label="Subject" placeholder="How can we help you today?" name={CONTACT_US_FIELDS.SUBJECT} onChange={handleChange} value={inputValues[CONTACT_US_FIELDS.SUBJECT]} />
          <Textarea
            label="Your Message"
            placeholder="Feel free to explain in detail"
            className="flex flex-col"
            rows="4"
            name={CONTACT_US_FIELDS.MESSAGE}
            onChange={handleChange}
            value={inputValues[CONTACT_US_FIELDS.MESSAGE]}
          />
          <button type="submit" className="primary w-full mt-[20px]" onClick={handleContactUs}>
            Submit
          </button>
          {fromErrorPage && (
            <Link>
              <button className="w-full text-[16px] leading-[24px] tracking-[-0.48px] font-[600] text-neutral-strong">
                Back to Homepage
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
