import PropTypes from "prop-types";
import Upload from "../Upload";
import { FILE_TYPE_PDF } from "../../constants/file";
import { useState } from "react";

UploadModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default function UploadModal({ isOpen, closeModal }) {
  const [file, setFile] = useState();

  return (
    <div className="fixed inset-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[#121212] text-xl flex flex-col items-center gap-[40px]">
      <div className="mt-[32px] flex flex-col gap-[8px] p-[32px] bg-white rounded-[20px] w-full max-w-[1186px]">
        <span className="leading-[20px] tracking-[-0.28px] font-[500] text-[14px]">
          Upload Financial Statement
        </span>
        <Upload
          onFileSelect={setFile}
          file={file}
          accept={[FILE_TYPE_PDF]}
          acceptLabel="1 PDF file only. Max size of 15MB"
        />
        <div className="flex flex-col justify-center items-center gap-[12px] w-full mt-[16px]">
          <button
            className={`flex h-[48px] w-full flex-col justify-center items-center rounded-[8px] text-[#fff] text-base font-semibold leading-[24px] ${
              file
                ? "bg-gradient-to-r from-[#023972] to-[#356CA5]"
                : "bg-[#D1D1D1]"
            }`}
          >
            Next
          </button>
          <button
            className="flex h-[48px] w-full flex-col justify-center items-center rounded-[8px] text-[#121212] text-base font-semibold leading-[24px]"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
