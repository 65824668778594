import { FORMULA_CALCULATE_OPERATIONS } from "../../constants/formula"

const operationCalculateMap = {
  [FORMULA_CALCULATE_OPERATIONS.ADD]: (total, value = 0) => {
    return total + value
  },
  [FORMULA_CALCULATE_OPERATIONS.SUBSTRACT]: (total, value = 0) => {
    return total - value
  },
  [FORMULA_CALCULATE_OPERATIONS.DIVIDE]: (total, value = 1) => {
    return total / value
  },
  [FORMULA_CALCULATE_OPERATIONS.MULTIPLY]: (total, value = 1) => {
    return total * value
  },
  [FORMULA_CALCULATE_OPERATIONS.PRINT]: (total, value = '') => {
    return value || total
  }
} 

export default function calculateTotalByOperation(total = 0, value, operation) {
  const calculate = operationCalculateMap[operation]

  if (!calculate) {
    console.error('operation not found:', operation)
    return total
  }

  return calculate(total, value)
}