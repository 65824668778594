import PropTypes from "prop-types";
import { CHART_DURATION } from "../../constants";
import Dropdowns from "../Dropdowns";

TimeControlChart.propTypes = {
    title: PropTypes.string.isRequired,
    handleSelectDuration: PropTypes.func.isRequired,
    selectedTime: PropTypes.any.isRequired,
};

export const timeOptions = [
    {
        label: "Last 12 months",
        value: "Last 12 months",
    },
    {
        label: "Last 6 months",
        value: "Last 6 months",
    },
];

export default function TimeControlChart({ title, handleSelectDuration, selectedTime = {
    duration: CHART_DURATION.MONTH,
    period: timeOptions?.[0],
} }) {
    return (
        <div className="flex gap-[8px] justify-between">
            <div className="text-[#121212] leading-[28px] text-[20px] font-bold">
                {title}
            </div>
            <div className="flex items-center gap-[20px]">
                <div className="flex p-[4px] rounded-[1000px] bg-[rgba(0,0,0,0.06)] h-[44px]">
                    <div
                        className={`flex justify-center items-center p-[16px] gap-[8px] ${selectedTime.duration === CHART_DURATION.MONTH
                            ? "rounded-[1000px] bg-[#fff] border-[0.5px] border-[#E5E5E5]"
                            : ""
                            }`}
                        onClick={() => handleSelectDuration(CHART_DURATION.MONTH)}
                    >
                        Month
                    </div>
                    <div
                        className={`flex justify-center items-center p-[16px] gap-[8px] ${selectedTime.duration === CHART_DURATION.WEEK
                            ? "rounded-[1000px] bg-[#fff] border-[0.5px] border-[#E5E5E5]"
                            : ""
                            }`}
                        onClick={() => handleSelectDuration(CHART_DURATION.WEEK)}
                    >
                        Week
                    </div>
                </div>
                <Dropdowns
                    data={timeOptions}
                    className={"flex-1"}
                    defaultValue="Last 12 months"
                />
            </div>
        </div>
    )
}