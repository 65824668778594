export const DOCUMENT_TYPE_PROPERTY_GENERATED = 'generated'

export const DOCUMENT_TYPE_ENUM = {
  INCOME_STATEMENT: "INCOME_STATEMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  CASH_FLOW_GENERATED: "CASH_FLOW_GENERATED",
  KEY_RATIOS: "KEY_RATIOS",
};

export const DOCUMENT_TYPE_UPLOADED = [
  {
    key: DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
    type: "required",
    text: "Income Statement",
  },
  {
    key: DOCUMENT_TYPE_ENUM.BALANCE_SHEET,
    type: "required",
    text: "Balance Sheet",
    hidePeriod: true,
  },
  { key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT, text: "Cash Flow Statement" },
];

export const DOCUMENT_TYPE_GENERATED = [
  // {
  //   key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
  //   type: "generated",
  //   formula_type: DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED,
  //   text: "Cash Flow Statement (Generated)",
  // },
  { key: DOCUMENT_TYPE_ENUM.KEY_RATIOS, type: DOCUMENT_TYPE_PROPERTY_GENERATED, text: 'Key Ratios' },
];

export const ALL_DOCUMENT_TYPES = [
  ...DOCUMENT_TYPE_UPLOADED,
  ...DOCUMENT_TYPE_GENERATED,
]

export const TOAST_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
};

export const MONTHS_OPTIONS = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

export const AUDITED_VALUE = "Audited";
export const UNAUDITED_VALUE = "Unaudited";

export const AUDITED_OPTIONS = [
  { value: "Audited", label: "Audited" },
  { value: "Unaudited", label: "Unaudited" },
];

export const PERIOD_MONTHS_OPTIONS = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

export const CURRENCY_OPTIONS = [
  {
    value: "USD",
    label: "United States Dollar",
  },
  {
    value: "EUR",
    label: "Euro",
  },
  {
    value: "GBP",
    label: "British Pound Sterling",
  },
  {
    value: "JPY",
    label: "Japanese Yen",
  },
  {
    value: "AUD",
    label: "Australian Dollar",
  },
  {
    value: "CAD",
    label: "Canadian Dollar",
  },
  {
    value: "IDR",
    label: "Indonesian Rupiah",
  },
  {
    value: "SGD",
    label: "Singapore Dollar",
  },
  {
    value: "CHF",
    label: "Swiss Franc",
  },
  {
    value: "CNY",
    label: "Chinese Yuan Renminbi",
  },
  {
    value: "HKD",
    label: "Hong Kong Dollar",
  },
  {
    value: "NZD",
    label: "New Zealand Dollar",
  },
  {
    value: "SEK",
    label: "Swedish Krona",
  },
  {
    value: "KRW",
    label: "South Korean Won",
  },
];

export const UNIT_OPTIONS = [
  {
    value: "Billions",
    label: "Billions",
  },
  {
    value: "Millions",
    label: "Millions",
  },
  {
    value: "Thousands",
    label: "Thousands",
  },
  {
    value: "",
    label: "-",
  },
]

export const MODE = {
  VIEW: "view",
  EDIT: "edit",
};

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

export const ROLES = {
  UNDERWRITER: "UNDERWRITER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
};

export const CHART_DURATION = {
  MONTH: "month",
  WEEK: "week",
};
