import { useEffect, useState } from "react";
import closeIcon from '../../assets/icons/icon_close.svg';

export default function PreviewAvatar({ file, url, ...otherProps }) {
    const [fileUrl, setFileUrl] = useState(url)
    useEffect(() => {
        if (file && !url) {
            setFileUrl(URL.createObjectURL(file))
        }
    }, [file])

    if (!fileUrl) {
        return null
    }

    const fileType = file.type;

    const isImage = fileType.startsWith('image/');

    if (isImage) {
        return <div className="flex flex-col gap-[8px] px-[16px] py-[12px] rounded-[8px] bg-black bg-opacity-[0.06]">
            <div className="flex justify-between items-start gap-[16px] w-full">
                <div className="flex gap-[16px]">
                    <img src={fileUrl} alt="avatar" width={36} height={36} className="rounded-[8px] w-[36px] h-[36px]" />
                    <div className="flex flex-col justify-center items-start gap-[8px] w-full">
                        <div className="text-[#121212] text-[14px] font-medium leading-[20px] tracking-[-0.28px]">
                            {file.name}
                        </div>
                        <div className="text-[#737373] text-[12px] leading-[16px]">{(file.size / 1024).toFixed(2)} KB</div>
                    </div>
                </div>
                <img src={closeIcon} alt="Close" className="min-w-10 w-10 inline-block cursor-pointer" onClick={() => setFileUrl(null)} />
            </div>
        </div>
    }

    return <div>Unsupported file type</div>

}