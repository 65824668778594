export default function Tooltip({
  children,
  tooltip,
  tooltipClassname,
  customStyle,
  handleClick = () => {},
}) {
  return (
    <div class="relative group" onClick={handleClick}>
      {children}
      <div
        class={`text-[10px] font-[400] leading-[12px] mb-[8px] shadow-popover absolute left-1/2 -translate-x-1/2 bottom-full w-[260px] invisible bg-tooltip opacity-0 text-[#121212] rounded-[2px] py-[4px] px-[8px] backdrop-blur-[20px] transition-opacity duration-300 group-hover:visible group-hover:opacity-100 ${tooltipClassname}`}
        style={{
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          ...customStyle,
        }}
      >
        {tooltip}
        <div class="absolute left-1/2 -translate-x-1/2 -bottom-1 h-[6px] w-[12px] rotate-45 bg-tooltip" />
      </div>
    </div>
  );
}
