import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_GET_COMPANIES, URL_COMPANIES } from "../../constants/url";
import ViewFinancialResult from "../../components/FinancialReport/ViewFinancialResult";
import { MODE, TOAST_TYPE } from "../../constants";
import EditFinancialResult from "../../components/FinancialReport/EditFinancialResult";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";

export default function ManageClient() {
  const { company_id } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader()
  const [searchParams] = useSearchParams();
  const { showToast } = useToaster()

  const [client, setClient] = useState(null)
  const fetchClient = async () => {
    setLoading(true)
    axios.get(`${API_GET_COMPANIES}/${company_id}`, {
      withCredentials: true,
    }).then(({ data: { data } }) => {
      setClient(data)
    }).catch(() => {
      showToast('Error when getting company data', TOAST_TYPE.ERROR)
      navigate(URL_COMPANIES)
    }).finally(() => {
      setLoading(false)
    })
  };

  const currentMode = searchParams.get('mode');


  useEffect(() => {
    if (company_id) {
      fetchClient();
    }
  }, [company_id]);

  const handleBack = () => {
    navigate(-1)
  }

  if (!client) {
    return null
  }

  if (currentMode === MODE.VIEW) {
    return (
      <ViewFinancialResult client={client} handleBack={handleBack} fetchClient={fetchClient} />
    )
  }

  return (
    <EditFinancialResult client={client} handleBack={handleBack} />
  )
  }
