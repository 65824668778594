import { useEffect, useState } from "react";
import ImagePreview from "../ImagePreview";
import PdfPreview from "../PdfPreview";

export default function Preview({ file, url, type, ...otherProps }) {
  const [fileUrl, setFileUrl] = useState(url)
  useEffect(() => {
    if (file && !url) {
      setFileUrl(URL.createObjectURL(file))
    }
  }, [file])

  useEffect(() => {
    if (url) {
      setFileUrl(url)
    }
  }, [url])

  if (!fileUrl) {
    return null
  }

  const fileType = file?.type || type;

  const isImage = fileType.startsWith('image/');

  if (isImage) {
    return <ImagePreview {...otherProps} url={fileUrl} file={file} />
  }

  const isPdf = fileType === 'application/pdf'

  if (isPdf) {
    return <PdfPreview {...otherProps} fileUrl={fileUrl} />
  }

  return <div>Unsupported file type</div>

}