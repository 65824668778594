import backIcon from "../../assets/icons/icon_back.svg";
import SearchInput from "../../components/SearchInput";
import iconSearch from "../../assets/icons/icon_search.svg";
import BaseButton from "../../components/BaseButton";
import CommonTable from "../../components/CommonTable/cop";
import { MEMBER_COLS, memberCols, mockData } from "./memberColumn";
import { useState } from "react";
import MemberModal from "../../components/MemberModal";
import { useNavigate } from "react-router-dom";
import { URL_DASHBOARD } from "../../constants/url";
import { SORT_ORDER } from "../../constants";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";

const ManageMember = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [members, setMembers] = useState(mockData);
  const [isAscCols, setIsAscCols] = useState({
    [MEMBER_COLS.NAME]: true,
    [MEMBER_COLS.EMAIL]: true,
  });

  const handleToggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleOpenModalEdit = () => {
    handleToggleModal();
    setIsEdit((prev) => !prev);
  };

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        members,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setMembers(
      sortByKey(
        members,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex-col gap-[16px]">
        <div className="pb-[16px] flex justify-between">
          <div className="flex gap-[16px] flex-1 items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={() => navigate(URL_DASHBOARD)}
            />
            <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Managing Team Member
            </span>
          </div>
          <div className="flex justify-center items-center gap-[20px]">
            <SearchInput />
            <BaseButton
              title={"Create New Member"}
              btnClassname="!h-[48px]"
              handleClick={handleToggleModal}
            />
          </div>
        </div>
      </div>
      <CommonTable
        minWidth={memberCols.minWidth}
        columns={memberCols.columns({
          handleEdit: handleOpenModalEdit,
          handleSort,
          isAscCols,
        })}
        rowsData={members}
        label="Financial Mapping"
      />
      {openModal && (
        <MemberModal closeModal={handleToggleModal} isEdit={isEdit} />
      )}
    </div>
  );
};

export default ManageMember;
