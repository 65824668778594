import PropTypes from "prop-types";

TableInput.propTypes = {
  className: PropTypes.string,
};

export default function TableInput({ ...props }) {
  const { id, name = id } = props;
  return (
    <input
      {...props}
      name={name}
      autoComplete="off"
      className="!py-[6px] !px-[16px] text-neutral-strong font-[600] text-[13px] leading-[16px] tracking-[-0.26px] placeholder:font-[400] float-right"
    />
  );
}
